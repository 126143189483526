import type { Locale } from '@allisone/react-components';

import { DPAResellers } from '../constants';
import { Role } from 'src/common/enums';
import type { Roles } from 'src/communication/hasura/generated/graphql';
import { App_Versions_Enum } from 'src/communication/hasura/generated/graphql';

export type { Users as User } from 'src/communication/hasura/generated/graphql';
export type RoleType = Roles;

type Locations = {
  location: {
    id: number;
    title: string;
    type: string;
    version: App_Versions_Enum;
  };
  isEnabled: boolean;
};

export type UserSchema = {
  lastname: string;
  firstname: string;
  id: number;
  email: string;
  contact_email: string | null;
  image: string | null;
  is_first: boolean;
  is_enabled: boolean;
  is_validated: boolean;
  renew_pwd: boolean;
  verification_code: string | null;
  rpps_number: string;
  locale: Locale;
  created_at: string;
  phone: string;
  locations: Locations[];
  roles: RoleType[];
  disclaimer_approved_at: Date | null;
  cgu_approved_at: Date | null;
  intercom_token_id: string;
};

export type UserType = UserSchema & {
  imageContent: string | null;
};

export const isDentist = (roles?: Role[] | null): boolean => roles?.includes(Role.DENTIST) || false;

export const isDirector = (roles?: Role[] | null): boolean =>
  roles?.includes(Role.DIRECTOR) || false;

export const isHeadOf = (roles?: Role[] | null): boolean => roles?.includes(Role.HEAD_OF) || false;

export const isManager = (roles?: Role[] | null): boolean =>
  roles?.some(
    (role) => role === Role.HEAD_OF || role === Role.DIRECTOR || role === Role.ACCOUNTANT
  ) || false;

export const isAccountant = (roles?: Role[] | null): boolean =>
  roles?.includes(Role.ACCOUNTANT) || false;

export const isStrictlyAccountant = (roles?: Role[] | null): boolean =>
  roles?.length === 1 && roles[0] === Role.ACCOUNTANT;

export const hasXrayEditionPermission = (roles?: Role[] | null): boolean =>
  roles?.some((role) => [Role.DENTIST, Role.DIRECTOR, Role.ASSISTANT].includes(role)) || false;

export const hasTreatmentEditionPermission = (roles?: Role[] | null): boolean =>
  roles?.some((role) => [Role.DENTIST, Role.DIRECTOR, Role.ASSISTANT].includes(role)) || false;

const rolesPriorities = [
  Role.BACKOFFICE_STAFF,
  Role.DIRECTOR,
  Role.HEAD_OF,
  Role.DENTIST,
  Role.ASSISTANT,
  Role.SECRETARY,
  Role.ACCOUNTANT,
];

export const getDefaultRole = (roles: Role[] | undefined): Role | undefined => {
  if (!roles || roles.length === 0) return undefined;
  return rolesPriorities.find((p) => roles.includes(p)) || roles[0];
};

type UserLocation = {
  location: {
    reseller?: string | null;
  };
};

export const isUserConcernedByDPAReseller = (locations: UserLocation[]): boolean => {
  const resellers = locations
    .map((location) => location.location.reseller)
    .filter((reseller) => reseller);
  return (
    !!resellers?.length &&
    !!resellers.find((reseller) => reseller && DPAResellers.includes(reseller))
  );
};
