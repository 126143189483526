import { Button, Modal, Title } from '@allisone/react-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ReactComponent as SuccessLogo } from '../asset/success.svg';
import { ReactComponent as WarningLogo } from '../asset/warning.svg';

import styles from './PaymentStatusModal.module.scss';

type Props = {
  type: 'success' | 'failed';
  show?: boolean;
  onRetry?(): void;
};

export const PaymentStatusModal: FC<Props> = (props) => {
  const navigateTo = useNavigate();
  const { show = true, onRetry = () => navigateTo('/'), type } = props;
  const { t } = useTranslation('paymentStatusModal');

  const Icon = () => (type === 'success' ? <SuccessLogo /> : <WarningLogo />);

  return (
    <Modal
      onRequestClose={() => undefined}
      isOpen={show}
      className={{ base: styles.modal }}
      overlayClassName={{ base: styles.overlay }}
    >
      <div className={styles.container}>
        <Icon />
        <div className={styles.subContainer}>
          <Title className={styles.text} color="#02007b" level="4" weight="normal">
            {t(type === 'success' ? 'success.title' : 'failed.title')}
          </Title>
        </div>
        <Button variant="primary" onClick={onRetry}>
          {t(type === 'success' ? 'success.cta' : 'failed.cta')}
        </Button>
      </div>
    </Modal>
  );
};
