import { getClasses, Loader, ProfilePicture, useOnClickOutside } from '@allisone/react-components';
import { FC, useState } from 'react';

import { TooltipContent } from './TooltipContent';
import { useAppStore } from 'src/controller/store';
import { useGetImageFromCdn } from 'src/ui/components/Image';

import styles from './HeaderMenu.module.scss';

export const HeaderMenu: FC = () => {
  const { user } = useAppStore();
  const { url, loading } = useGetImageFromCdn(user?.image);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useOnClickOutside<HTMLDivElement>(() => setIsVisible(false));

  const toggleTooltip = () => {
    setIsVisible((prevIsVisible) => !prevIsVisible);
  };

  if (!user) return null;
  return (
    <div ref={ref}>
      <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
        {loading ? (
          <Loader size={30} />
        ) : (
          <ProfilePicture
            size="2.5rem"
            imageSrc={url}
            firstname={user.firstname}
            lastname={user.lastname}
          />
        )}
      </div>

      <div
        className={getClasses(
          styles.tooltipContentContainer,
          isVisible && styles['tooltipContentContainer--show']
        )}
      >
        <TooltipContent onClick={() => setIsVisible(false)} />
      </div>
    </div>
  );
};
