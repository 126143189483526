import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericPdfModal } from '../GenericPdfModal';
import { PATH_PDF_CGU } from 'src/controller/constants';
import { useGetFilePath } from 'src/controller/hooks/useGetFilePath';

type Props = {
  loading: boolean;
  handleClick(): void;
  handleClose(): void;
};

export const CguModal: FC<Props> = (props) => {
  const { t } = useTranslation('cgu');
  const pdfPath = useGetFilePath(PATH_PDF_CGU);

  return <GenericPdfModal {...props} buttonLabel={t('accept')} pdfPath={pdfPath} />;
};
