export enum ConnectionShape {
  HEXAGON = 6,
  OCTAGON = 7,
  TRI_LOBE = 9,
  SIX_LOBE = 13,
  THREE_SPLINE = 15,
  FOUR_SPLINE = 22,
  NO_ANTI_ROTATIONAL = 21,
  TRIANGLE = 3,
  SQUARE = 4,
  PENTAGON = 5,
  FOUR_LOBE = 10,
  FIVE_LOBE = 11,
  EIGHT_LOBE = 14,
  SIX_SPLINE = 16,
  EIGHT_SPLINE = 17,
  ONE_PIECE_ABUTMENT = 19,
  ONE_PIECE_BALL = 20,
  CUSTOM = 2,
}
