import { App_Versions_Enum, UserFieldsFragment } from 'src/communication/hasura/generated/graphql';
import { isUserAllowedInVersion } from 'src/controller/utils/dentistry/isUserAllowedInVersion';

export const getVersions = (userLocations: UserFieldsFragment['locations']) => {
  const isUserFDA = isUserAllowedInVersion(userLocations, App_Versions_Enum.Fda);
  const isUserUKCA = isUserAllowedInVersion(userLocations, App_Versions_Enum.Ukca);
  const isUserCE = isUserAllowedInVersion(userLocations, App_Versions_Enum.Ce);

  return {
    fda: isUserFDA,
    ukca: isUserUKCA,
    ce: isUserCE,
    commercial: !isUserCE && !isUserFDA && !isUserUKCA,
  };
};
