import { ChildElementTypeEnum } from './ChildElementTypeEnum';
import { ElementTypeEnum } from './ElementTypeEnum';

export const PathologiesEnum = {
  DECAY_NO_NERVE_CONTACT: ChildElementTypeEnum['DECAY_NO_NERVE_CONTACT'],
  DECAY_NEAR_NERVE: ChildElementTypeEnum['DECAY_NEAR_NERVE'],
  DECAY_CONTACT_NERVE: ChildElementTypeEnum['DECAY_CONTACT_NERVE'],
  ROOT_DECAY: ChildElementTypeEnum['ROOT_DECAY'],
  PERIAPICAL_LESION: ElementTypeEnum.PERIAPICAL_LESION,
  ROOT: 'ROOT',
  AMALGAM_NO_NERVE_CONTACT: ChildElementTypeEnum['AMALGAM_NO_NERVE_CONTACT'],
  AMALGAM_NEAR_NERVE: ChildElementTypeEnum['AMALGAM_NEAR_NERVE'],
  AMALGAM_CONTACT_NERVE: ChildElementTypeEnum['AMALGAM_CONTACT_NERVE'],
};
