import { isValid } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EnrichedBilling } from 'src/common/types';
import { useFormatDate } from 'src/controller/hooks/useDate';
import { getCurrency } from 'src/controller/utils/basics/getCurrency';

import styles from './AmountToPaidTodayTooltip.module.scss';

type Props = {
  billing: EnrichedBilling;
};

export const Content: FC<Props> = ({ billing }) => {
  const { prorata } = billing;

  const formatDate = useFormatDate();
  const { t } = useTranslation('centrePaymentModal');
  const currency = getCurrency(billing.price.currency);

  if (!prorata) return null;

  const invoiceStartDate =
    isValid(new Date(prorata.invoiceStartDate)) &&
    formatDate(new Date(prorata.invoiceStartDate), 'PP');

  const invoiceNextDate =
    isValid(new Date(prorata.invoiceNextDate)) &&
    formatDate(new Date(prorata.invoiceNextDate), 'PP');
  const details = `${prorata.details.numIntervalsToPay} ${t(
    `interval.${prorata.details.interval}`
  )} x ${billing.quantity} ${t('columns.formula').toLowerCase()}`;

  return (
    <div className={styles.details}>
      <div className={styles.title}>
        <h2>{t('amountDetailsTitle')}</h2>
        <div className={styles.description}>
          {t('amountDetails', { invoiceStartDate, invoiceNextDate })}
        </div>
      </div>

      <div className={styles.row}>
        <span className={styles.label}>{details}</span>
        <span className={styles.value}>
          {prorata.totalBeforeDiscountExclTax / 100} {currency}
        </span>
      </div>
      {prorata.discountName && (
        <div className={styles.row}>
          <span className={styles.labelSecondary}>{prorata.discountName}</span>
          <span className={styles.value}>
            {prorata.discountValue / 100} {currency}
          </span>
        </div>
      )}

      <div className={styles.row}>
        <span className={styles.labelTotal}>{t('columns.totalPriceExclTax')}</span>
        <span className={styles.valueTotal}>
          {prorata.totalExclTax / 100} {currency}
        </span>
      </div>

      <div className={styles.row}>
        <span className={styles.labelSecondary}>{t('columns.tax')}</span>
        <span className={styles.value}>
          {prorata.taxAmount / 100} {currency}
        </span>
      </div>

      <div className={styles.row}>
        <span className={styles.labelTotal}>{t('columns.totalPriceInclTax')}</span>
        <span className={styles.valueTotal}>
          {prorata.totalInclTax / 100} {currency}
        </span>
      </div>
    </div>
  );
};
