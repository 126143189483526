import { getClasses, Loader } from '@allisone/react-components';
import { FC, memo } from 'react';

import { ScoredBoundingBox } from '../../TooltipSpotimplant/types/ScoredBoundingBox';

import styles from '../styles/BoundingBoxViewer.module.scss';

type BoundingBox = {
  xmax: number;
  xmin: number;
  ymax: number;
  ymin: number;
};

type Props = {
  src: string | null;
  bboxes: ScoredBoundingBox[];
  isLoading: boolean;
  onChange(bboxes: ScoredBoundingBox[]): void;
};

const BoundingBoxViewerBase: FC<Props> = ({ src, bboxes, isLoading, onChange }) => {
  const onClick = (selectedIdx: number) => {
    if (onChange) {
      const newBoxes = bboxes.map((x, idx) => {
        return {
          ...x,
          selected: idx === selectedIdx ? !x.selected : x.selected,
        };
      });
      onChange(newBoxes);
    }
  };

  if (!src) {
    return <div className={styles.bboxViewerContainer}></div>;
  }
  return (
    <div className={styles.bboxViewerContainer}>
      <img className={styles.bboxAnnotatedImage} src={src} />
      {isLoading && <Loader screenCenter={false} className={styles.loader} />}

      {!isLoading && bboxes
        ? bboxes.map((bbox, idx) => (
            <span
              key={idx}
              className={getClasses(
                styles.annotation,
                styles.selectable,
                bbox.selected ? styles.selected : ''
              )}
              onClick={() => onClick(idx)}
              style={getBoxStyles(bbox.box)}
            />
          ))
        : ''}
    </div>
  );
};

const getBoxStyles = (box: BoundingBox) => ({
  left: box.xmin * 100 + '%',
  top: box.ymin * 100 + '%',
  width: (box.xmax - box.xmin) * 100 + '%',
  height: (box.ymax - box.ymin) * 100 + '%',
});

export const BoundingBoxViewer = memo(BoundingBoxViewerBase);
