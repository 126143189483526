import { Dropdown, Icon, OptionProps, Title, Variant } from '@allisone/react-components';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LocationIcon } from './LocationIcon';
import { AllisonePlusVersion } from 'src/common/types';
import { useAppStore } from 'src/controller/store';

import styles from './CurrentLocation.module.scss';

export const CurrentLocation: FC = () => {
  const { user, currentLocation, setCurrentLocation } = useAppStore();
  const { t } = useTranslation('home');
  const locations = useMemo(
    () =>
      user?.locations.map(({ location: { id, title } }) => ({
        id,
        label: title,
        value: id,
        icon: <LocationIcon title={title} />,
      })) ?? [],
    [user?.locations]
  );
  const isLocationWithAllisonePlusV2 = !!user?.locations.find(
    ({ location }) => location.allisonePlusVersion === AllisonePlusVersion.V2
  );
  const lastLocationId: string | null = localStorage.getItem('lastLocation');
  const lastLocation = locations.find((location) => location.id === Number(lastLocationId));

  const handleSelectLocation = (location: OptionProps<number | undefined>) => {
    setCurrentLocation(location.value);
    if (location.id) localStorage.setItem('lastLocation', location.id.toString());
  };

  useEffect(() => {
    if (lastLocation) {
      setCurrentLocation(lastLocation.value);
    } else if (locations.length > 0) {
      setCurrentLocation(locations[0]?.value);
    }
  }, [locations]);

  if (locations.length === 0 || !user || !isLocationWithAllisonePlusV2) return null;

  if (locations.length === 1) {
    return (
      <div className={styles.title}>
        {locations[0].icon}
        <Title className="styles.title.marge" level="4">
          {locations[0].label}
        </Title>
      </div>
    );
  }

  return (
    <div>
      <Dropdown
        variant={Variant.SECONDARY_V2}
        buttonClassName={styles.dropdown}
        isAnimatedBorder
        options={locations}
        title={t('dropdown.whichLocation')}
        titleIcon={<Icon iconName="LocationDefault" className={styles.icon} />}
        multiple={false}
        noResultLabel={t('dropdown.noResult')}
        value={currentLocation}
        onSelect={handleSelectLocation}
        oneLine
      />
    </div>
  );
};
