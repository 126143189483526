export enum ScrewdriverShape {
  HEXAGON = 1,
  SIX_POINT_STAR = 2,
  SQUARE = 3,
  FLAT = 4,
  FIVE_POINT_STAR = 6,
  EIGHT_POINT_STAR = 7,
  FOUR_LOBES = 8,
  CROSS = 9,
  OTHER = 5,
}
