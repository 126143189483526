import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocations } from 'src/communication/hasura/query';
import { useAppStore } from 'src/controller/store';
import { isManager } from 'src/controller/utils/User';
import Link from 'src/ui/components/Link';

type Props = {
  className?: string;
  onClick?(): void;
};

export const MyCenterLink: FC<Props> = ({ className, onClick }) => {
  const { t } = useTranslation('location');
  const { locations } = useLocations();
  const hasOneLocation = locations?.length === 1;
  const { currentRoles } = useAppStore();
  const link = hasOneLocation ? `/locations/${locations?.[0]?.id}` : '/locations';
  const label = hasOneLocation ? t('myCenter') : t('myCenters');
  if (!isManager(currentRoles)) return null;
  return (
    <Link to={link} className={className} onClick={onClick}>
      {label}
    </Link>
  );
};
