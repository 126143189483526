import { useContext } from 'react';

import { TreatmentContext } from '../context';
import { Treatment } from '../types';

export const useTreatmentContext = (): Treatment => {
  const treatment = useContext(TreatmentContext);
  if (!treatment) throw new Error('useTreatment can only be used in children of TreatmentProvider');
  return treatment;
};
