import { getLocale, Locale } from '@allisone/react-components';
import { format, parseISO } from 'date-fns';

import { buildAnnotations } from './buildAnnotations';
import { buildRetroBoneLevel } from './buildRetroBoneLevel';
import { buildTooth } from './buildTooth';
import { getTranslatedAnatomies } from './getTranslatedAnatomies';
import { Xray } from 'src/common/types';
import { imageKeyPrefix } from 'src/controller/constants';
import { XRayImage } from 'src/ui/components/XrayAnnotationTool/types';
import i18n from 'src/ui/i18n';

export const buildXrayImage = (xray: Xray): XRayImage => {
  const result: XRayImage = {
    id: xray.id,
    type: xray.type,
    url: xray.url,
    teeth: buildTooth(xray.teeth),
    fileName: xray.name,
    creationDate: format(parseISO(xray.creationDate), 'P - HH:mm', {
      locale: getLocale(i18n.language as Locale),
    }),
    annotations: buildAnnotations(xray.elements) ?? [],
    retroBoneLevels: buildRetroBoneLevel(xray.retroBoneLevels),
    retroRulerSegments: xray.retroRulerSegments,
    projections: xray.projection,
    consultationId: xray.consultationId.toString(),
    periodontics: xray.detectedPeriodonticLines,
    anatomies: getTranslatedAnatomies(xray.anatomies),
    oralStructures: xray.oralStructures,
    centralTeeth: xray.centralTeeth,
  };
  if (xray.imageKey) {
    result.imageKeys = {
      large: imageKeyPrefix.LARGE + xray.imageKey,
      medium: imageKeyPrefix.MEDIUM + xray.imageKey,
      small: imageKeyPrefix.SMALL + xray.imageKey,
    };
  }
  return result;
};
