import { Table, Title, XIcon } from '@allisone/react-components';
import { useTranslation } from 'react-i18next';

import { useGetColumns, useStripeGuard } from '../hooks';
import { BenificiaryContent } from './BeneficiaryContent';
import { BrandLogo } from 'src/ui/components/BrandLogo';
import { CFC } from 'src/ui/components/FCWithChildren';

import styles from './StripeModal.module.scss';

export const StripeModalGuard: CFC = ({ children }) => {
  const { t } = useTranslation('centrePaymentModal');
  const {
    billings,
    shouldShowStripeGuard,
    skippable,
    isBeneficiary,
    onPay,
    handleClose,
    paymasters,
  } = useStripeGuard();
  const columns = useGetColumns(onPay);

  const isTryingToResetPassword = ['/new-password'].includes(location.pathname);
  if (isTryingToResetPassword || !shouldShowStripeGuard) return <>{children}</>;

  return (
    <>
      <div className={styles.header}>
        <div className={styles.logo}>
          <BrandLogo withBrandName />
        </div>

        {skippable && (
          <button onClick={handleClose} className={styles.cross}>
            <XIcon />
          </button>
        )}
      </div>

      <div className={styles.container}>
        {isBeneficiary ? (
          <BenificiaryContent paymasters={paymasters} />
        ) : (
          <>
            <Title level="3">{t('title')}</Title>
            <Table
              rowKey="id"
              data={billings}
              className={styles.table__header}
              noData={null}
              columns={columns}
            />
          </>
        )}
      </div>
    </>
  );
};
