import { Button, Modal } from '@allisone/react-components';
import { FC, useEffect, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import styles from '../styles/GenericPdfModal.module.scss';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

type DocumentLoadSuccess = {
  numPages: number;
};

type Props = {
  loading: boolean;
  handleClick(): void;
  handleClose(): void;
  pdfPath: string;
  buttonLabel: string;
};

export const GenericPdfModal: FC<Props> = (props) => {
  const { handleClick, handleClose, loading, pdfPath, buttonLabel } = props;

  const [numPages, setNumPages] = useState<number>(0);
  const [pdfBlob, setPdfBlob] = useState<Blob>();

  // This line is mandatory for a vite app to find the correct pdf.worker.js
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  useEffect(() => {
    fetch(pdfPath)
      .then((res) => {
        //We have to generate error manually as aws provides a page with error that is converted to blob
        if (res.status !== 200) {
          console.error('Error fetching pdf : ', res.statusText);
        }
        return res.blob();
      })
      .then((blob) => setPdfBlob(blob));
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: DocumentLoadSuccess) => setNumPages(numPages);
  const PDF = useMemo(
    () =>
      Array.from(new Array(numPages), (_: unknown, index: number) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          className={styles.page}
          onLoadError={handleClose}
          onRenderError={handleClose}
        />
      )),
    [numPages]
  );

  return (
    <Modal
      isOpen
      closable
      onRequestClose={handleClose}
      className={{ base: styles.modal }}
      overlayClassName={{ base: styles.overlay }}
    >
      <div className={styles.container}>
        {pdfBlob && (
          <Document
            file={pdfBlob}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={handleClose}
            className={styles.content}
          >
            {PDF}
          </Document>
        )}
        <Button
          onClick={handleClick}
          className={styles.action}
          loading={loading}
          disabled={loading}
        >
          {buttonLabel}
        </Button>
      </div>
    </Modal>
  );
};
