import { StateCreator } from 'zustand';

import { Store } from '..';
import { storeResetFns } from '../resetAllStore';
import { ElementFilterEnum, XrayViewerStore, XrayViewerStoreState } from './types';

const initialXrayViewerState: XrayViewerStoreState = {
  elementsFilterMode: ElementFilterEnum.ON,
  hiddenElements: [],
};

export const useXrayViewerStore: StateCreator<Store, [], [], XrayViewerStore> = (set) => {
  storeResetFns.add(() => set(initialXrayViewerState));
  return {
    ...initialXrayViewerState,
    setElementsFilterMode(elementsFilterMode) {
      set({ elementsFilterMode });
    },
    setHiddenElements(hiddenElements) {
      set({ hiddenElements });
    },
  };
};
