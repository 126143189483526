export enum ElementFilterEnum {
  CUSTOM,
  ON,
  OFF,
  NOT_VALIDATED,
}

export type XrayViewerStoreState = {
  elementsFilterMode: ElementFilterEnum; //manual if user has custom filter, undefined when not validated yet
  hiddenElements: string[];
};

type XrayViewerStoreAction = {
  setElementsFilterMode: (toggleStatus: ElementFilterEnum) => void;
  setHiddenElements: (hiddenElements: string[]) => void;
};

export type XrayViewerStore = XrayViewerStoreState & XrayViewerStoreAction;
