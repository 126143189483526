import { FetchResult } from '@apollo/client';

import {
  UserDocument as GET_USER,
  InsertTreatmentPreferencesMutation,
  useInsertTreatmentPreferencesMutation as Mutation,
  InsertTreatmentPreferencesMutationVariables as Variables,
} from 'src/communication/hasura/generated/graphql';

type Return = (
  variables: Variables
) => Promise<
  FetchResult<InsertTreatmentPreferencesMutation, Record<string, any>, Record<string, any>>
>;

export const useInsertTreatmentPreferences = (): Return => {
  const [insertTreatmentPreferences] = Mutation();

  const insertTreatmentPreferencesMutation = async (variables: Variables) =>
    insertTreatmentPreferences({
      variables,
      refetchQueries: [GET_USER],
    });

  return insertTreatmentPreferencesMutation;
};
