export const parseLocalStorage = <T = any>(key: string): null | T => {
  const value = localStorage.getItem(key);

  if (!value) return null;

  try {
    return JSON.parse(value) as T;
  } catch {
    return null;
  }
};
