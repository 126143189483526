export enum TreatmentEnum {
  ALIGNER = 'ALIGNER',
  BRIDGE = 'BRIDGE',
  DENTURE = 'DENTURE',
  SCALING = 'SCALING',
  SINUS_LIFT = 'SINUS_LIFT',
  ROOT_PLANING = 'ROOT_PLANING',
  INLAY_ONLAY = 'INLAY_ONLAY',
  IMPLANT = 'IMPLANT',
  BONE_GRAFT = 'BONE_GRAFT',
  ROOT_CANAL = 'ROOT_CANAL',
  POST_CORE = 'POST_CORE',
  PULP_CAPPING = 'PULP_CAPPING',
  FIBER_POST = 'FIBER_POST',
  FILLING = 'FILLING',
  CROWN = 'CROWN',
  EXTRACTION = 'EXTRACTION',
  APICAL_RESECTION = 'APICAL_RESECTION',
  ALL_ON_SURGERY = 'ALL_ON_SURGERY',
  ALL_ON_PROSTHESIS = 'ALL_ON_PROSTHESIS',
  GUM_LEFT = 'Gum_left',
  GUM_RIGHT = 'Gum_right',
  GUM_STANDARD = 'Gum_standard',
  GUM_SOLO = 'Gum_solo',
  NIGHT_GUARD = 'NIGHT_GUARD',
  ROOT_CANAL_RETREATMENT = 'ROOT_CANAL_RETREATMENT',
  VENEER = 'VENEER',
  WHITENING = 'WHITENING',
  OTHER = 'OTHER',
}
