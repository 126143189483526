import { t } from 'i18next';

import { Anatomy } from 'src/ui/components/XrayAnnotationTool/types/Anatomy';

export const getTranslatedAnatomies = (anatomies: Anatomy[] | undefined): Anatomy[] => {
  if (!anatomies) return [];
  return anatomies.map((anatomy) => ({
    ...anatomy,
    type: t(`elements.${anatomy.type}`, { ns: 'elements' }),
  }));
};
