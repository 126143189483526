import { Role } from 'src/common/enums';
import { User, UserLocation } from 'src/common/types';
import { useUsersQuery } from 'src/communication/hasura/generated/graphql';
import { RoleType } from 'src/controller/utils/User';

export type UseUser = Pick<User, 'id' | 'lastname' | 'firstname' | 'email' | 'image'> & {
  locations: {
    locationId: UserLocation['location_id'];
    isEnabled: UserLocation['isEnabled'];
  }[];
  roles: {
    role: RoleType;
  }[];
};

export type UseUsersParams = {
  roles?: Role[];
  locationIds?: number[];
  withLocations?: boolean;
  withRoles?: boolean;
  isEnabled?: boolean;
  isValidated?: boolean;
};

type UseUsersReturn = {
  users?: UseUser[];
  loading: boolean;
};

export const useUsers = ({
  withLocations = false,
  withRoles = false,
  roles,
  locationIds,
  isEnabled,
  isValidated,
}: UseUsersParams): UseUsersReturn => {
  const { data, loading } = useUsersQuery({
    variables: {
      where: {
        users_users_locations: {
          location_id: { _in: locationIds },
          isEnabled: { _eq: isEnabled },
        },
        users_users_roles: {
          role: { value: { _in: roles } },
        },
        is_validated: { _eq: isValidated },
      },
      withLocations,
      withRoles,
    },
  });

  return {
    users: data?.users,
    loading,
  };
};
