import { Link } from '@allisone/react-components';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useLinks } from './useLinks';
import { registerUserEvent } from 'src/communication/hasura/query';
import { useWindowSize } from 'src/controller/hooks/useWindowSize';
import { Route } from 'src/ui/router';

type UseConnectedLayout = {
  isCollapsed: boolean;
  menuLinks: Link[];
  handleNavigate(path: string): void;
  withoutLayout: boolean;
};

export const useConnectedLayout = (): UseConnectedLayout => {
  const navigateTo = useNavigate();
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const menuLinks = useLinks();

  const isCollapsed =
    width < 1200 ||
    [Route.STATISTICS, Route.HOME, Route.PATIENTS, Route.ROOT].some((p) => pathname.includes(p));

  const handleNavigate = useCallback(
    (path: string) => {
      registerUserEvent(`menu.${path.substring(1)}`, {});
      navigateTo(path);
    },
    [navigateTo]
  );

  const withoutLayout = [
    '/acquisition',
    '/treatment',
    '/partner',
    '/analysis',
    '/report',
    '/fullscreen',
    '/annotations',
    '/clinic',
  ].some((r) => pathname.includes(r));

  return {
    isCollapsed,
    menuLinks,
    handleNavigate,
    withoutLayout,
  };
};
