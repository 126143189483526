import { Control, Controller, Path } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { PATH_DPA_NO_RESELLER, PATH_DPA_RESELLER, PATH_PDF_CGU } from 'src/controller/constants';
import { useGetFilePath } from 'src/controller/hooks/useGetFilePath';
import { useAppStore } from 'src/controller/store';
import { isUserConcernedByDPAReseller } from 'src/controller/utils/User';

import styles from '../styles/CguInput.module.scss';

type Props<T extends Record<string, any>> = {
  name: Path<T>;
  control: Control<T>;
};

export const CguInput = <T extends Record<string, unknown>>({ control, name }: Props<T>) => {
  const { t } = useTranslation('about');
  const { user } = useAppStore();
  const pathPdf = useGetFilePath(PATH_PDF_CGU);
  const userHasDPAReseller = user && isUserConcernedByDPAReseller(user.locations);
  const DPALink = useGetFilePath(userHasDPAReseller ? PATH_DPA_RESELLER : PATH_DPA_NO_RESELLER);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <div className={styles.cgu}>
          <input
            className={styles.inputCheckbox}
            type="radio"
            onBlur={onBlur}
            onChange={() => onChange(true)}
            checked={value === true}
            ref={ref}
            id="cgu"
          />
          <label className={styles.label} htmlFor="cgu">
            <Trans t={t} i18nKey={'acceptCgu'}>
              <a href={pathPdf} target="_blank" className={styles.link} rel="noreferrer" />
              <a href={DPALink} target="_blank" className={styles.link} rel="noreferrer" />
            </Trans>
          </label>
        </div>
      )}
    />
  );
};
