import { Button, Text, Title, useEventListener, useToaster } from '@allisone/react-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useNewUserInformationForm } from '../hooks/useNewUserInformationForm';
import { NewUserInformationDTO } from '../types/NewUserInformation';
import { CguInput } from './CguInput';
import { axiosPost } from 'src/communication/api/utils/axiosUtils';
import { useUpdateUser } from 'src/communication/hasura/query';
import { getAllowedRoles, getAuthTokens } from 'src/controller/Keycloak/keycloak';
import { useAppStore } from 'src/controller/store';
import { getDefaultRole } from 'src/controller/utils/User';
import { PhoneInput } from 'src/ui/components/phone';
import { Route } from 'src/ui/router';

import styles from '../styles/NewUserInformationForm.module.scss';

export const NewUserInformationForm: FC = () => {
  const { t } = useTranslation(['login', 'input']);
  const { show } = useToaster();
  const { updateUser } = useUpdateUser();
  const { user } = useAppStore();
  const { handleSubmit, watch, formState, control } = useNewUserInformationForm();
  const phone = watch('phone');
  const navigateTo = useNavigate();
  const token = getAuthTokens() ?? undefined;
  const currentRoles = getAllowedRoles();
  const role = getDefaultRole(currentRoles);

  const onSubmit = async ({ phone }: Pick<NewUserInformationDTO, 'phone'>) => {
    if (!user) return;
    try {
      await updateUser(user.id, {
        phone,
        cgu_approved_at: new Date().toISOString(),
      });
      if (!user || !token || !role)
        throw new Error('Error while sending the welcome email, please contact the support');
      axiosPost({
        url: '/api/v2/internal/messenger/sendWelcomeEmail',
        token,
        role,
        data: {},
      });

      navigateTo(Route.ROOT);
    } catch (error) {
      show({ text: error as string, type: 'error' });
    }
  };

  useEventListener('keypress', ({ key }: Pick<KeyboardEvent, 'key'>) => {
    if (key === 'Enter' && formState.isValid) {
      onSubmit({ phone });
    }
  });

  return (
    <div className={styles.container_layout}>
      <div className={styles.container_layout__content}>
        <Title level={'2'}>{t('input:title')}</Title>
        <Text weight="light">{''}</Text>
      </div>
      <div className={styles.phoneInput}>
        <PhoneInput name="phone" control={control} />
      </div>
      <CguInput name="cgu" control={control} />
      <Button
        variant="primary"
        className={styles.button}
        disabled={!formState.isValid}
        onClick={handleSubmit(onSubmit)}
      >
        {t('input:save')}
      </Button>
    </div>
  );
};
