import { Button, ErrorIcon } from '@allisone/react-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import styles from './NotFound.module.scss';

export const ErrorComponent = () => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();

  const handleSubmit = () => {
    navigateTo('/');
    // force refresh the page even if we are already on root
    navigateTo(0);
  };

  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorContent}>
        <ErrorIcon width="108" height="204" className={styles.iconAndButton} />
        <span style={{ margin: 'auto' }}>{t('errorNotFound')}</span>
        <Button className={styles.iconAndButton} variant="primary" onClick={handleSubmit}>
          {t('home')}
        </Button>
      </div>
    </div>
  );
};
