import { FetchResult } from 'apollo-boost';

import {
  UserDocument as GET_USER,
  UpdateTreatmentPreferencesMutation,
  useUpdateTreatmentPreferencesMutation,
  UpdateTreatmentPreferencesMutationVariables as Variables,
} from 'src/communication/hasura/generated/graphql';

type Return = (
  variables: Variables
) => Promise<
  FetchResult<UpdateTreatmentPreferencesMutation, Record<string, any>, Record<string, any>>
>;

export const useUpdateTreatmentPreferences = (): Return => {
  const [updateTreatmentPreferences] = useUpdateTreatmentPreferencesMutation();

  const updateTreatmentPreferencesMutation = async (variables: Variables) =>
    updateTreatmentPreferences({ variables, refetchQueries: [GET_USER] });

  return updateTreatmentPreferencesMutation;
};
