import { TreatmentEnum } from '@allisone/react-components';
import { chain } from 'lodash';

import { WaitingTreatment } from '../../../../../types';

export const teethTreatments = [
  TreatmentEnum.BRIDGE,
  TreatmentEnum.DENTURE,
  TreatmentEnum.ALL_ON_SURGERY,
];

type Fn = (treatments: WaitingTreatment[]) => WaitingTreatment[][];

export const groupTreatmentsByTeeth: Fn = (treatments) =>
  chain(treatments)
    .groupBy(({ toothNumbers, operationType }) =>
      teethTreatments.includes(operationType.name) ? operationType.name : toothNumbers.join(',')
    )
    .values()
    .sort(([aTreatment], [bTreatment]) => {
      if (!aTreatment || !bTreatment) return 0;
      // Always put denture and bridge on top of the list
      if (teethTreatments.includes(aTreatment.operationType.name)) {
        return -1;
      }

      // this is for firefox, for some reason we have to check is bTreatment is a denture or a brdige
      if (teethTreatments.includes(bTreatment.operationType.name)) {
        return 1;
      }

      if (!aTreatment.toothNumbers[0] || !bTreatment.toothNumbers[0]) {
        return 0;
      }

      return aTreatment.toothNumbers[0].localeCompare(bTreatment.toothNumbers[0]);
    })
    .value();
