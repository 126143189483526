import { convertToPng, useToaster } from '@allisone/react-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchImage } from './fetchImage';
import { urlCache } from './urlCache';
import { getAuthTokens } from 'src/controller/Keycloak/keycloak';
import { useAppStore } from 'src/controller/store';
import { getMimeType } from 'src/controller/utils/file/mimeType';

export const useGetImageFromCdn = (imgSrc?: string | null) => {
  const { t } = useTranslation('layout');
  const { currentRole } = useAppStore();
  const authTokens = getAuthTokens();
  const { show } = useToaster();
  const [url, setUrl] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!authTokens || !currentRole || !imgSrc) return;
    setLoading(true);

    const cacheValue = urlCache.get(imgSrc);
    let urlPromise;
    if (cacheValue) {
      urlPromise = cacheValue;
    } else {
      urlPromise = fetchImage(imgSrc, authTokens, currentRole)
        .then(async (blob) => {
          // Fallback for old patient file > convert image to PNG before use
          const mimetype = getMimeType(imgSrc);
          if (
            mimetype !== 'image/png' &&
            mimetype !== 'image/jpg' &&
            mimetype !== 'application/octet-stream'
          ) {
            const file = new File([blob], imgSrc, { type: mimetype });
            const { file: pngFile } = await convertToPng(file);
            return pngFile;
          }
          return blob;
        })
        .then((blob) => URL.createObjectURL(blob))
        .catch(() => {
          urlCache.reset(imgSrc);
          throw new Error('Could not fetch image from CDN');
        });
      urlCache.set(imgSrc, urlPromise);
    }

    urlPromise
      .then(setUrl)
      .catch((error) => {
        if (error.name === 'AbortError') return;
        show({ type: 'error', text: t('header.picture.error') });
      })
      .finally(() => setLoading(false));
  }, [imgSrc]);

  return { url, loading };
};
