import { useLocation } from 'react-router';

import { CurrentLocation } from './CurrentLocation';
import { HeaderMenu } from './HeaderMenu';
import { Welcome } from './Welcome';
import { CFC } from 'src/ui/components/FCWithChildren';
import { Route } from 'src/ui/router';

import styles from '../ConnectedLayout.module.scss';

export const Header: CFC = () => {
  const { pathname } = useLocation();
  const isWelcomeVisible = [Route.HOME, Route.ROOT].some((route) => pathname === route);

  return (
    <header className={styles.header}>
      <div>{isWelcomeVisible && <Welcome />}</div>
      <div className={styles.headerOptions}>
        <CurrentLocation />
        <HeaderMenu />
      </div>
    </header>
  );
};
