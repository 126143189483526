import * as Sentry from '@sentry/react';

import { axiosGet } from './utils/axiosUtils';
import { getAllowedRoles, getAuthTokens } from 'src/controller/Keycloak/keycloak';
import { getDefaultRole } from 'src/controller/utils/User';

type ResultType = {
  id: string;
  patient: {
    id: string;
    firstname: string;
    lastname: string;
    birthdate?: string;
  };
  lastConsultation: any;
};

export const getPatientFromPartner = async (patientid: string): Promise<ResultType> => {
  const url = `/api/v2/internal/patientPartner/${patientid}`;
  const authTokens = getAuthTokens() ?? undefined;
  const currentRoles = getAllowedRoles();
  const role = getDefaultRole(currentRoles);
  try {
    if (!authTokens || !role) throw new Error('Token or role not found');

    const { data } = await axiosGet({
      url,
      token: authTokens,
      role,
    });
    return data as ResultType;
  } catch (error) {
    Sentry.captureException(error, { extra: { url } });
    throw new Error(error as any);
  }
};
