import { ElementSubtype } from 'src/common/types';
import { useElementTypesQuery } from 'src/communication/hasura/generated/graphql';
import { useErrorManager } from 'src/controller/hooks/useErrorManager';

type Return = {
  loading: boolean;
  elementTypes?: ElementSubtype[];
};

export const useElementTypesProvider = (): Return => {
  const { data, loading, error } = useElementTypesQuery();
  useErrorManager(error);

  return {
    loading,
    elementTypes: data?.element_types,
  };
};
