import { useCallback } from 'react';

import {
  UpdateUserMutationVariables,
  useUpdateUserMutation,
} from 'src/communication/hasura/generated/graphql';
import { useAppStore } from 'src/controller/store';

class PermissionError extends Error {}

type UpdateUser = (
  userId: UpdateUserMutationVariables['userId'],
  data: UpdateUserMutationVariables['data']
) => Promise<void>;

export const useUpdateUser = () => {
  const [userMutation, { loading }] = useUpdateUserMutation();
  const { setUser } = useAppStore();

  const updateUser: UpdateUser = useCallback(async (userId, data) => {
    const response = await userMutation({ variables: { userId, data } });
    const updatedUser = response.data?.update_users_by_pk;
    if (!updatedUser) throw new PermissionError();
    setUser(updatedUser);
  }, []);

  return { updateUser, loading };
};
