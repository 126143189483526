import { getClasses } from '@allisone/react-components';
import React from 'react';
import { useNavigate } from 'react-router';

import styles from './Link.module.scss';

type LinkProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  to: string;
};

const Link = ({ children, to, ...rest }: LinkProps) => {
  const navigate = useNavigate();

  return (
    <button
      {...rest}
      className={getClasses(styles.container, rest.className)}
      onClick={() => navigate(to)}
    >
      {children}
    </button>
  );
};

export default Link;
