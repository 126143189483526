import { lazyWithRetry } from './lazyWithRetry';

export const About = lazyWithRetry(() => import('src/ui/pages/AboutPage'));
export const Account = lazyWithRetry(() => import('src/ui/pages/AccountPage'));
export const Acquisition = lazyWithRetry(() => import('src/ui/pages/Acquisition'));
export const Home = lazyWithRetry(() => import('src/ui/pages/Home'));
export const HomePartner = lazyWithRetry(() => import('src/ui/pages/HomePartner'));
export const Treatment = lazyWithRetry(() => import('src/ui/pages/Treatment/components'));
export const Invoices = lazyWithRetry(() => import('src/ui/pages/Invoices'));
export const Patients = lazyWithRetry(() => import('src/ui/pages/Patients'));
export const Location = lazyWithRetry(() => import('src/ui/pages/Locations/Location'));
export const TreatmentPage = lazyWithRetry(() => import('src/ui/pages/Treatment'));
export const Locations = lazyWithRetry(() => import('src/ui/pages/Locations'));
export const Stats = lazyWithRetry(() => import('src/ui/pages/Stats'));
export const NotFound = lazyWithRetry(() => import('src/ui/pages/NotFound'));
export const Analysis = lazyWithRetry(() => import('src/ui/pages/Analysis'));
export const TreatmentPreferences = lazyWithRetry(
  () => import('src/ui/pages/TreatmentPreferences')
);
export const EducationalSheets = lazyWithRetry(() => import('src/ui/pages/EducationalSheets'));
