import {
  AllisoneTrashIcon,
  ConfirmModal,
  ConfirmModalTranslation,
  Modal,
} from '@allisone/react-components';
import { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FullScreenLayout } from 'src/ui/router/layouts';

import styles from './FullScreenUploadModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onRequestClose(): void;
  children: ReactNode;
  setIsConfirm?(isConfirm: boolean): void;
  showConfirm?: boolean;
  confirmTranslation?: ConfirmModalTranslation;
  isConfirm?: boolean;
  icon?: ReactNode;
  storageKey?: string;
  title?: string;
};

export const FullScreenUploadModal = forwardRef<HTMLCanvasElement, Props>((props, ref) => {
  const {
    children,
    isOpen,
    showConfirm = false,
    isConfirm,
    icon,
    storageKey,
    title,
    onClose,
    onRequestClose,
    setIsConfirm,
  } = props;

  const { t } = useTranslation('upload');

  const confirmTranslation: ConfirmModalTranslation = {
    title: t('confirmation.title'),
    confirm: t('confirmation.yes'),
    cancel: t('confirmation.cancel'),
    checkboxLabel: t('confirmation.check'),
    text: t('confirmation.text'),
  };

  const isConfirmModalVisible = showConfirm && confirmTranslation && isConfirm;
  const closeConfirm = () => setIsConfirm?.(false);

  return (
    <>
      <canvas ref={ref} style={{ display: 'none' }} />
      <Modal
        isOpen={isOpen}
        onAfterClose={onClose}
        onRequestClose={onRequestClose}
        className={{ base: styles.modal }}
        overlayClassName={{ base: styles.overlay }}
        closable
        closeIconClassname={styles.closeIcon}
      >
        <FullScreenLayout title={title}>{children}</FullScreenLayout>
      </Modal>
      {isConfirmModalVisible && (
        <ConfirmModal
          translation={confirmTranslation}
          storageKey={storageKey}
          isOpen={isConfirm}
          onCancel={closeConfirm}
          onClose={closeConfirm}
          onConfirm={onClose}
          titleIcon={icon || <AllisoneTrashIcon style={{ height: '2.5rem' }} />}
        />
      )}
    </>
  );
});
