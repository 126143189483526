import { useContext } from 'react';

import { ElectronContext } from '../../context';
import { ElectronConnect } from '../../types';

export const useElectronConnect = (): ElectronConnect => {
  const ctx = useContext(ElectronContext);
  if (!ctx) throw new Error('Error on ElectronContext');
  return ctx;
};
