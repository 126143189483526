import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import { MyCenterLink } from './MyCenterLink';
import { clearStorage } from 'src/communication/apollo/clearStorage';
import { Locale_Enum } from 'src/communication/hasura/generated/graphql';
import { registerUserEvent, useGetStripePortalUrls } from 'src/communication/hasura/query';
import { useTreatmentPreferencesAllowed } from 'src/controller/hooks/useTreatmentPreferencesAllowed';
import { useAppStore } from 'src/controller/store';
import { resetAllStore } from 'src/controller/store/resetAllStore';
import { isManager } from 'src/controller/utils/User';
import Link from 'src/ui/components/Link';

import styles from './TooltipContent.module.scss';

type Props = {
  onClick?(): void;
};

export const TooltipContent: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation([
    'about',
    'layout',
    'settings',
    'treatmentPreferences',
    'educationalSheet',
  ]);
  const { currentRoles, user, locationVersions, isEducationalSheetEnabled } = useAppStore();
  const isUserFDA = locationVersions.fda;
  const auth = useAuth();
  const { areTreatmentPreferencesAllowed } = useTreatmentPreferencesAllowed();

  const { data } = useGetStripePortalUrls();

  const userLocale = user?.locale === Locale_Enum.It ? Locale_Enum.En : user?.locale;
  const helpLink = `https://intercom.help/allisone/${userLocale}`;

  const isInvoicesLinkVisible = !isManager(currentRoles) && data && data?.length > 0;

  const onLogout = () => {
    clearStorage();
    resetAllStore();
    auth.signoutRedirect({
      post_logout_redirect_uri: window.location.origin,
    });
  };

  return (
    <div className={styles.tooltipContainer} onClick={onClick}>
      <div className={styles.listItem}>
        <Link to="/account" className={styles.link}>
          {t('settings:title')}
        </Link>
        <MyCenterLink className={styles.link} />
        {areTreatmentPreferencesAllowed && (
          <Link to="/preferences" className={styles.link}>
            {t('treatmentPreferences:myTreatments')}
          </Link>
        )}
        {isInvoicesLinkVisible && (
          <Link to="/invoices" className={styles.link}>
            {t('settings:invoices.title')}
          </Link>
        )}
        {isEducationalSheetEnabled && (
          <Link to="/educational-sheets" className={styles.link}>
            {t('educationalSheet:educationalSheets')}
          </Link>
        )}
        {!isUserFDA && (
          <a
            onClick={() => registerUserEvent('help', {})}
            href={helpLink}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            {t('layout:header.help')}
          </a>
        )}
        <Link to="/about" className={styles.link}>
          {t('about:title')}
        </Link>
        <span onClick={onLogout} className={styles.link}>
          {t('layout:header.logout')}
        </span>
      </div>
    </div>
  );
};
