export const toothNumberingConversionTable: { [key: string]: string } = {
  '18': '1',
  '17': '2',
  '16': '3',
  '15': '4',
  '14': '5',
  '13': '6',
  '12': '7',
  '11': '8',
  '21': '9',
  '22': '10',
  '23': '11',
  '24': '12',
  '25': '13',
  '26': '14',
  '27': '15',
  '28': '16',
  '38': '17',
  '37': '18',
  '36': '19',
  '35': '20',
  '34': '21',
  '33': '22',
  '32': '23',
  '31': '24',
  '41': '25',
  '42': '26',
  '43': '27',
  '44': '28',
  '45': '29',
  '46': '30',
  '47': '31',
  '48': '32',
};
