import { CircleCap } from '@allisone/react-components';
import { FC } from 'react';

import style from './Menu.module.scss';

export const CircleButton: FC = () => {
  const handleCircleClick = () => {
    window.open('https://community.allisone.ai/', '_blank');
  };

  return (
    <div className={style.circleButton} onClick={handleCircleClick}>
      <CircleCap />
    </div>
  );
};
