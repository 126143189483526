import { Locale } from 'src/common/types';

export const getLocaleFromString = (string: string): Locale => {
  if (string.includes('fr')) return Locale.Fr;
  if (string.includes('es')) return Locale.Es;
  if (string.includes('it')) return Locale.It;
  if (string.includes('de')) return Locale.De;
  if (string.includes('pt')) return Locale.Pt;
  if (string.includes('sv')) return Locale.Sv;
  if (string.includes('ca')) return Locale.Ca;
  else return Locale.En;
};
