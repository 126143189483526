import { FC } from 'react';

import { CguModal } from '../../../../components/modals/CguModal';
import { DisclaimerModal } from '../../../../components/modals/DisclaimerModal';
import { useModalGuard } from './hooks';

export const ModalGuard: FC = () => {
  const {
    showCgu,
    showDisclaimer,
    loading,
    handleClickCgu,
    handleClickDisclaimer,
    handleCloseCgu,
    handleCloseDisclaimer,
  } = useModalGuard();

  if (showCgu)
    return <CguModal loading={loading} handleClick={handleClickCgu} handleClose={handleCloseCgu} />;

  if (showDisclaimer)
    return (
      <DisclaimerModal
        loading={loading}
        handleClick={handleClickDisclaimer}
        handleClose={handleCloseDisclaimer}
      />
    );

  return null;
};
