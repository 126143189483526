import { ApolloError, gql, useQuery } from '@apollo/client';

const GET_PORTAL_URLS = gql`
  query getPortalUrls {
    portals(input: $input) @rest(path: "/stripe/portals", method: "GET") {
      list
    }
  }
`;

type UseGetStripePortalUrls = {
  data?: PortalItem[];
  loading: boolean;
  error?: ApolloError;
};

type PortalItem = { name: string; portalUrl: string; locationId: number };

export const useGetStripePortalUrls = (): UseGetStripePortalUrls => {
  const { data, loading, error } = useQuery<{
    portals: {
      list: PortalItem[];
    };
  }>(GET_PORTAL_URLS);

  return { data: data?.portals.list, loading, error };
};
