import { getClasses, Icon } from '@allisone/react-components';
import { FC } from 'react';

import styles from './CurrentLocation.module.scss';

type Props = {
  title: string;
};

const colorLocation = [
  'var(--color-malibu)',
  'var(--color-purple-dark)',
  'var(--color-turquoise)',
  'var(--color-glossy-pink)',
  'var(--color-violet)',
];

export const LocationIcon: FC<Props> = ({ title }) => (
  <Icon
    iconName="Location"
    style={{
      ['--color-location' as string]: colorLocation[title.charCodeAt(0) % colorLocation.length],
    }}
    className={getClasses(styles.icon, styles.iconLocation)}
  />
);
