import { useContext } from 'react';

import { ZoomContext } from '../context';
import { ZoomContextValues } from '../types';

export const useZoom = (): ZoomContextValues => {
  const value = useContext(ZoomContext);
  if (!value) throw new Error('useZoom can only be used in children of ZoomProvider');
  return value;
};
