import { Button, getClasses } from '@allisone/react-components';
import * as Sentry from '@sentry/react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useAppStore } from 'src/controller/store';
import { ReactComponent as ErrorLogo } from 'src/ui/assets/error.svg';

import styles from './Error.module.scss';

type Props = {
  error: Error | string;
  resetError?(): void;
};

export const Error: FC<Props> = ({ error, resetError }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const { user } = useAppStore();

  useEffect(() => {
    Sentry.captureException(error, {
      extra: { reason: 'Unhandled error in Dentist app (shows the error page).' },
    });
  }, []);

  const onClick = () => {
    resetError?.();
    navigateTo('/');
  };

  return (
    <div className={getClasses(styles.error, user && styles.loggedIn)}>
      <p>{t('errorGeneric')}.</p>
      <p>{typeof error === 'string' ? error : error.message}</p>

      <ErrorLogo />

      <Button onClick={onClick}>{t('home')}</Button>
    </div>
  );
};
