import { getClasses, InformationCircleIcon } from '@allisone/react-components';
import { FC, useEffect, useRef, useState } from 'react';

import { Content } from './Content';
import { EnrichedBilling } from 'src/common/types';

import styles from './AmountToPaidTodayTooltip.module.scss';

type Props = {
  billing: EnrichedBilling;
};

export const AmountToPaidTodayTooltip: FC<Props> = ({ billing }) => {
  const [isTooltipVisible, setTooltipVisibility] = useState(false);
  const [topPosition, setTopPositon] = useState(0);
  const childrenRef = useRef<HTMLInputElement>(null);
  const tooltipRef = useRef<HTMLInputElement>(null);

  // Edit position of tooltip (bottom or top)
  useEffect(() => {
    if (!tooltipRef.current || !childrenRef.current) return;
    const tooltipPos = tooltipRef.current.getBoundingClientRect();
    const childrenPos = childrenRef.current.getBoundingClientRect();
    if (tooltipPos.bottom === 0) return;
    if (childrenPos.y + 200 > window.innerHeight) {
      setTopPositon(childrenPos.y - (tooltipPos.height + 10));
    } else {
      setTopPositon(childrenPos.y + childrenPos.height + 10);
    }
  }, [isTooltipVisible]);

  return (
    <div className={styles.amountToPaidTodayTooltip}>
      <span ref={childrenRef}>
        <InformationCircleIcon
          className={styles.icon}
          onMouseOver={() => setTooltipVisibility(true)}
          onMouseOut={() => setTooltipVisibility(false)}
        />
      </span>
      <div
        ref={tooltipRef}
        className={getClasses(styles.tooltip, isTooltipVisible && styles.tooltip__open)}
        style={{ top: `${topPosition}px` }}
      >
        <Content billing={billing} />
      </div>
    </div>
  );
};
