import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Locale } from 'src/common/types';
import { getLocaleFromString } from 'src/controller/utils/app/getLocaleFromString';

const languagePreferences = navigator.language;
const locale = getLocaleFromString(languagePreferences);

//defensive code for unit tests
if (i18n.use) {
  i18n.use(initReactI18next).init({
    fallbackLng: Locale.En,
    lng: locale,
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18nStore: 'added',
    },
  });
}

export default i18n;
