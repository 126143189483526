import { Role } from 'src/common/enums';
import { AuthTokens } from 'src/common/types';

type FetchImage = (
  url: string,
  tokens: AuthTokens,
  currentRole?: Role,
  controller?: AbortController
) => Promise<Blob>;

const env = import.meta.env.VITE_ENVIRONMENT;
const isLocal = env && env.toString() === 'local';

export const fetchImage: FetchImage = (url, tokens, currentRole, controller) =>
  fetch(url, {
    signal: controller?.signal,
    method: 'GET',
    headers: {
      Authorization: `${tokens.token_type} ${tokens.access_token}`,
      'X-Hasura-Role': currentRole ? currentRole.toString() : '',
      ...(isLocal && { bypass: 'LOTK42' }), //Bypass lambda aws in local to acces dev bucket
    },
  }).then((res) => {
    //We have to generate error manually as aws provides a page with error that is converted to blob
    if (res.status !== 200) {
      return Promise.reject(new Error('Error fetching aws file : ' + res.statusText));
    }
    return res.blob();
  });

// This adds baseUrl to imageKey
export const getCdnUrlFromImageKey = (imageKey: string) => {
  const imageBaseUrl = import.meta.env.VITE_IMAGE_BASE_URL;
  return imageBaseUrl + '/' + imageKey;
};
