import { Button, Modal } from '@allisone/react-components';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BrandLogo } from '../../BrandLogo';

import styles from './DisclaimerModal.module.scss';

type Props = {
  loading: boolean;
  handleClick(): void;
  handleClose(): void;
};

export const DisclaimerModal: FC<Props> = ({ handleClick, handleClose, loading }) => {
  const { t } = useTranslation('disclaimer');
  const qaraMail = import.meta.env.VITE_QARA_MAIL;

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      closable
      className={{ base: styles.modal }}
      overlayClassName={{ base: styles.overlay }}
    >
      <div className={styles.container}>
        <div className={styles.logo}>
          <BrandLogo
            className={styles.logo__short}
            classNameBrandName={styles.logo__brandName}
            withBrandName
          />
        </div>
        <Trans t={t} i18nKey="text">
          <p>
            <b>Purpose</b> purposeText
          </p>
          <p>
            <b>Description</b>
          </p>
          <p>text</p>
          <p>
            <b>Mesure</b>
            text
          </p>
          <p>
            Contact <a href={`mailto: ${qaraMail} `}>{qaraMail}</a>
          </p>
        </Trans>
        <div className={styles.action}>
          <Button onClick={handleClick} disabled={loading} loading={loading}>
            {t('button')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
