import { getLocale, Locale } from '@allisone/react-components';
import { format, parseISO } from 'date-fns';
import { useCallback } from 'react';

import { useAppStore } from '../store';

export const useFormatDate = () => {
  const { user } = useAppStore();

  return useCallback(
    (date: Date, formatting = 'P') => {
      const locale = user?.locale as Locale;
      return format(date, formatting, { locale: getLocale(locale) });
    },
    [user]
  );
};

export const useGetDate = () => {
  const formatDate = useFormatDate();

  return useCallback(
    (date: string) => {
      const hour = formatDate(parseISO(date), 'HH:mm:ss');
      const format = hour === '00:00:00' ? 'P' : 'P - p';
      const formattedDate = formatDate(parseISO(date), format);

      return formattedDate;
    },
    [formatDate]
  );
};
