import { Xray_Type_Enum } from 'src/communication/hasura/generated/graphql';

const retroTypes = [Xray_Type_Enum.Intraoral, Xray_Type_Enum.Periapical, Xray_Type_Enum.Bitewing];

const analyzableTypes = [Xray_Type_Enum.Panoramic, ...retroTypes];

export const getIsRetro = (xrayType?: Xray_Type_Enum): boolean => {
  if (!xrayType) return false;
  return retroTypes.includes(xrayType);
};

export const isAnalyzable = (xrayType?: Xray_Type_Enum): boolean => {
  if (!xrayType) return false;
  return analyzableTypes.includes(xrayType);
};
