import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as LicenceInactif } from 'src/ui/assets/public/licence_inactif.svg';

import styles from './StripeModal.module.scss';

type Props = {
  paymasters?: string[];
};

export const BenificiaryContent: FC<Props> = ({ paymasters }) => {
  const { t } = useTranslation('centrePaymentModal');
  const contact = paymasters?.join(', ');
  return (
    <div className={styles.beneficiaryContent}>
      <LicenceInactif />
      <p className={styles.title}>{t('beneficiariesTitle')}</p>
      <p className={styles.subtitle}>
        <Trans t={t} i18nKey="beneficiariesSubtitle">
          text <span>{{ contact }}</span> text
        </Trans>
      </p>
    </div>
  );
};
