import { useLocation } from 'react-router';

import { Header } from './Header';
import { Menu } from './Menu';
import { useConnectedLayout } from './useConnectedLayout';
import { BrandLogo } from 'src/ui/components';
import { CFC } from 'src/ui/components/FCWithChildren';

import styles from './ConnectedLayout.module.scss';

export const ConnectedLayout: CFC = ({ children }) => {
  const { menuLinks, handleNavigate, withoutLayout } = useConnectedLayout();
  const { pathname } = useLocation();

  if (withoutLayout) return <>{children}</>;

  return (
    <div className={styles.layout} style={{ gridTemplateColumns: `6.25rem auto` }}>
      <Menu
        links={menuLinks}
        onLinkClicked={handleNavigate}
        onClickBrandLogo={() => handleNavigate('/')}
        logo={<BrandLogo withReseller />}
      />
      <div className={styles.content}>
        <Header />
        <div key={pathname} className={styles.body}>
          {children}
        </div>
      </div>
    </div>
  );
};
