import { client } from '.';

let isClearing = false;

export const clearStorage = () => {
  if (!isClearing) {
    isClearing = true;
    client.clearStore().finally(() => {
      isClearing = false;
    });
  }
};
