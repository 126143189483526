import { createContext } from 'react';

import { CFC } from '../../../FCWithChildren';
import {
  ElementTooltipContextValues,
  useElementTooltipProvider,
} from './useElementTooltipProvider';

export const ElementTooltipContext = createContext<ElementTooltipContextValues | null>(null);

export const ElementTooltipProvider: CFC = ({ children }) => (
  <ElementTooltipContext.Provider value={useElementTooltipProvider()}>
    {children}
  </ElementTooltipContext.Provider>
);
