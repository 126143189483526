import {
  AllisoneBrandNameWhite,
  AllisoneLogoShort,
  getClasses,
  ResellerCrossIcon,
} from '@allisone/react-components';
import { FC } from 'react';

import { useGetImageFromCdn } from '../Image';
import { useAppStore } from 'src/controller/store';
import { getResellerLogoUrl } from 'src/controller/utils/location/getResellerLogoUrl';

import styles from './BrandLogo.module.scss';

type Props = {
  className?: string;
  classNameBrandName?: string;
  withBrandName?: boolean;
  withReseller?: boolean;
};

export const BrandLogo: FC<Props> = ({
  className,
  classNameBrandName,
  withBrandName = false,
  withReseller = false,
}) => {
  const { reseller } = useAppStore();
  const logoUrl = getResellerLogoUrl(reseller, withBrandName);
  const { url } = useGetImageFromCdn(logoUrl);

  return (
    <div className={styles.container}>
      <div className={styles.allisoneLogo}>
        <AllisoneLogoShort className={getClasses(styles.logo, className)} />
        <AllisoneBrandNameWhite
          className={getClasses(
            styles.logo,
            classNameBrandName,
            !withBrandName ? styles['logo--hidden'] : styles['logo--show']
          )}
        />
      </div>

      {withReseller && url && (
        <>
          <ResellerCrossIcon className={styles.crossIcon} />
          <img alt={`${logoUrl}`} src={url} style={{ height: withBrandName ? '3rem' : '3.5rem' }} />
        </>
      )}
    </div>
  );
};
