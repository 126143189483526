import { useEffect, useState } from 'react';

import { Coord2D, EditionMode } from '../../types';
import { useZoom } from '../../XrayContainer/Zoom';
import { useSATContext } from '../XrayAnnotationToolProvider/useSATContext';
import { Size, TooltipPositionSet } from './useElementTooltipProvider';
import { useTreatmentContext } from 'src/ui/pages/Treatment';

const correctOffsetPosition = (border: number, size: number, parentSize: number) => {
  if (border > 0 && border + size > parentSize) {
    return Math.max(0, parentSize - size - 10);
  }
  return border;
};

export const useSetTooltipPosition = (
  isTooltipEditor = false,
  top = 0,
  left = 0
): TooltipPositionSet => {
  const { arePointsClosing } = useZoom();
  const { shapeManager, mouseLayerPosition } = useSATContext();
  const { editionMode } = useTreatmentContext();
  const { newShapePath } = shapeManager;
  const [tooltipPosition, setTooltipPosition] = useState<Coord2D>({
    x: 0,
    y: 0,
  });
  const [tooltipSize, setTooltipSize] = useState<Size>();
  const [containerSize, setContainerSize] = useState<Size>();

  const isPolygonCreating =
    [EditionMode.POLYGON, EditionMode.DRAW_RECT_SHAPE].includes(editionMode) && arePointsClosing;

  useEffect(() => {
    if (isPolygonCreating && tooltipSize && containerSize) {
      const x = correctOffsetPosition(mouseLayerPosition.x, tooltipSize.width, containerSize.width);
      const y = correctOffsetPosition(
        mouseLayerPosition.y,
        tooltipSize.height,
        containerSize.height
      );
      setTooltipPosition({ x, y });
      return;
    }
    if (top === 0 && left === 0) return;
    if (!isPolygonCreating && !isTooltipEditor) {
      setTooltipPosition({ x: left, y: top });
    } else {
      const y = correctOffsetPosition(top, tooltipSize?.height ?? 0, containerSize?.height ?? 0);
      setTooltipPosition({ x: left, y });
    }
  }, [
    isPolygonCreating,
    mouseLayerPosition,
    tooltipSize,
    containerSize,
    newShapePath,
    top,
    left,
    isTooltipEditor,
  ]);

  return {
    isPolygonCreating,
    tooltipPosition,
    tooltipSize,
    setTooltipSize,
    containerSize,
    setContainerSize,
  };
};
