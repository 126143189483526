import { ReactNode } from 'react';
import { AuthProvider } from 'react-oidc-context';

import { registerUserEvent } from 'src/communication/hasura/query';

const KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL as string;
const KEYCLOAK_REALM = (import.meta.env.VITE_KEYCLOAK_REALM as string) || 'allisone';
const KEYCLOAK_CLIENT_ID = (import.meta.env.VITE_KEYCLOAK_CLIENT_ID as string) || 'dental-webapp';

type UserProps = {
  children: ReactNode;
};

const home = window.location.origin;
const redirectExceptions = ['/renew-password', '/forgot-password', '/login'];

export const KeycloakAuthProvider = (props: UserProps) => {
  const isRedirectException = redirectExceptions.some((exception) =>
    window.location.pathname.includes(exception)
  );

  const redirectUri = isRedirectException ? home : window.location.href;

  const oidcConfig = {
    authority: `${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}`,
    client_id: KEYCLOAK_CLIENT_ID,
    redirect_uri: redirectUri,

    onSigninCallback: () => {
      window.history.replaceState({}, document.title, window.location.pathname);
      registerUserEvent('login', {});
    },
  };
  return <AuthProvider {...oidcConfig}>{props.children}</AuthProvider>;
};
