import { BrandLogo, Link, ListMenuLinks } from '@allisone/react-components';
import { FC, ReactNode } from 'react';

import { CircleButton } from './CircleButton';

import styles from './Menu.module.scss';

type MenuProps = {
  links: Link[];
  onLinkClicked: (path: string) => void;
  onClickBrandLogo: () => void;
  logo: ReactNode;
};

export const Menu: FC<MenuProps> = ({ links, logo, onLinkClicked, onClickBrandLogo }) => {
  return (
    <nav className={styles.menu}>
      <BrandLogo onClickBrandLogo={onClickBrandLogo}>{logo}</BrandLogo>

      <ListMenuLinks isCollapsed links={links} onLinkClicked={onLinkClicked} />

      <CircleButton />
    </nav>
  );
};
