import { Control } from 'react-hook-form';
import { default as Input } from 'react-phone-number-input/react-hook-form';

import { useAppStore } from 'src/controller/store';

import styles from './PhoneInput.module.scss';

type Props<T extends Record<string, any>> = {
  name: string;
  control: Control<T>;
  style?: React.CSSProperties;
  defaultValue?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
};

export const PhoneInput = <T extends Record<string, unknown>>(props: Props<T>) => {
  const { name, defaultValue, control, style, onChange, value } = props;
  const { user } = useAppStore();

  // Roleback on French phone number as our null country database history is mainly from France
  const locationCountry = user?.locations[0]?.location.country;
  const defaultCountry = locationCountry ?? 'FR';

  if (!user) return null;
  return (
    <Input
      style={style}
      international
      countryCallingCodeEditable={false}
      defaultCountry={defaultCountry as 'FR'}
      name={name}
      control={control}
      defaultValue={defaultValue}
      className={styles.phoneInput}
      onChange={onChange}
      value={value}
    />
  );
};
