import { useTranslation } from 'react-i18next';

import { useAppStore } from '../store';
import { getLocaleFromString } from '../utils/app/getLocaleFromString';
import { Locale_Enum } from 'src/communication/hasura/generated/graphql';

// Please check that the files that you need are available in all the languages specified in this hook
export const useGetFilePath = (fileName: string) => {
  const { i18n } = useTranslation();
  const { user } = useAppStore();
  const userLocale = user?.locale;
  let locale = getLocaleFromString(userLocale ?? i18n.language);
  const isEnPath = [Locale_Enum.De, Locale_Enum.Pt, Locale_Enum.Ca, Locale_Enum.Sv].includes(
    locale
  );
  if (isEnPath) {
    locale = Locale_Enum.En;
  }
  const S3_URL = import.meta.env.VITE_ALLISONE_WEBAPP_S3;

  return `${S3_URL}/${locale}/${fileName}`;
};
