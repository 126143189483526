import { useContext } from 'react';
import { createContext } from 'react';

import { AnnotationsValues } from './useAnnotationsProvider';

export const AnnotationsContext = createContext<AnnotationsValues | null>(null);

export const useAnnotationContext = () => {
  const value = useContext(AnnotationsContext) as AnnotationsValues;
  if (!value) {
    throw new Error(`${useAnnotationContext.name} only be used in children of SATProvider`);
  }
  return value;
};
