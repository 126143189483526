import { getPatientFromPartner } from 'src/communication/api/getPatientFromPartner';
import { Consultation } from 'src/communication/hasura/generated/graphql';
import { PatientData } from 'src/ui/components/PatientForm/types';

type GetPatient = {
  id: string;
  patient: PatientData | undefined;
  lastConsultation: Consultation | undefined;
};

export const getPatientPartner = async (
  patientPartnerId: string | undefined
): Promise<GetPatient> => {
  if (!patientPartnerId)
    throw new Error('no patient partner id', {
      cause: {
        userMsg: 'patientNotFound',
      },
    });

  const patientPartner = await getPatientFromPartner(patientPartnerId);
  if (!patientPartner)
    throw new Error('Patient partner not found', {
      cause: {
        userMsg: 'patientNotFound',
      },
    });
  return {
    id: patientPartner.id,
    patient: {
      ...patientPartner.patient,
      birthdate: patientPartner.patient?.birthdate
        ? new Date(patientPartner.patient?.birthdate)
        : undefined,
    },
    lastConsultation: patientPartner.lastConsultation,
  };
};
