import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import { boolean, object, SchemaOf, string } from 'yup';

import { NewUserInformationDTO } from '../types/NewUserInformation';
import { some } from 'src/controller/utils/basics/logic';

type NewUserInformationForm = UseFormReturn<
  {
    phone: string;
    cgu: boolean;
  },
  Record<string, unknown>
> & {
  isValidField(field: keyof NewUserInformationDTO): boolean;
  isErrorField(field: keyof NewUserInformationDTO): boolean;
};

export const useNewUserInformationForm = (): NewUserInformationForm => {
  const validationSchema: SchemaOf<NewUserInformationDTO> = object().shape({
    cgu: boolean().required('input.required').oneOf([true]),
    phone: string().required('input.required'),
  });

  const DEFAULT_VALUES = {
    phone: '',
    cgu: false,
  };

  const hookForm = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  });

  const isValidField = (field: keyof NewUserInformationDTO) =>
    (hookForm.getValues(field) !== DEFAULT_VALUES[field] && !hookForm.formState.errors[field]) ??
    false;

  const isErrorField = (field: keyof NewUserInformationDTO) =>
    hookForm.getValues(field) !== DEFAULT_VALUES[field] && some(hookForm.formState.errors[field]);

  return { isValidField, isErrorField, ...hookForm };
};
