import { defaultStyles, useTooltip } from '@visx/tooltip';
import { CSSProperties, RefObject, useEffect, useRef, useState } from 'react';

import { Coord2D, EditionMode } from '../../types';
import { useAnnotationContext } from '../AnnotationsProvider/useAnnotationContext';
import { useSetTooltipPosition } from './useSetTooltipPosition';
import { useTreatmentContext, useXRay } from 'src/ui/pages/Treatment';

export type Size = {
  width: number;
  height: number;
};

export type TooltipPositionSet = {
  isPolygonCreating: boolean;
  setTooltipSize(tooltipSize?: Size): void;
  setContainerSize(containerSize?: Size): void;
  tooltipPosition: Coord2D;
  tooltipSize?: Size;
  containerSize?: Size;
};

type rectShape = {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
};

export type ElementTooltipContextValues = {
  svgRef: RefObject<HTMLDivElement>;
  // Below type cannot be set
  tooltipStyles: any;
  tooltipPositionSet: TooltipPositionSet;
  isDraggingAnnotation: boolean;
  setIsDraggingAnnotation(isDraggingAnnotation: boolean): void;
  parentBounds: rectShape;
  tooltipLeft: number | undefined;
  tooltipTop: number | undefined;
  showTooltip: (args?: any) => void;
  hideTooltip: (args?: any) => void;
};

export const useElementTooltipProvider = (): ElementTooltipContextValues => {
  const svgRef = useRef<HTMLDivElement>(null);
  const { lockedTooltip, setLockedTooltip, isTooltipEditor, setIsTooltipEditor } = useXRay();
  const { selected } = useAnnotationContext();
  const { fullScreenVisible, editionMode } = useTreatmentContext();
  const [isDraggingAnnotation, setIsDraggingAnnotation] = useState(false);

  const tooltipStyles: CSSProperties = {
    ...defaultStyles,
    padding: 0,
    borderRadius: 25,
    zIndex: 100,
  };

  const { tooltipLeft, tooltipTop, showTooltip, hideTooltip } = useTooltip();
  const left = lockedTooltip?.left ?? tooltipLeft;
  const top = lockedTooltip?.top ?? tooltipTop;
  const tooltip = lockedTooltip?.annotation || selected;

  // Below useEffect allow to return to TooltipViewer automatically each time the tooltip id changes
  useEffect(() => {
    if (fullScreenVisible && !lockedTooltip) setIsTooltipEditor(false);
    else if (!fullScreenVisible) setIsTooltipEditor(false);
  }, [tooltip?.id, fullScreenVisible]);

  // Below useEffect avoid dupplication of setLockedTooltip?.(null) in Footer of legends components
  useEffect(() => {
    if (editionMode === EditionMode.VISUAL) setLockedTooltip(null);
  }, [editionMode]);

  const tooltipPositionSet = useSetTooltipPosition(isTooltipEditor, top, left);

  const containerWidth = svgRef.current?.clientWidth ?? 0;
  const containerHeight = svgRef.current?.clientHeight ?? 0;
  const maxTooltipWidth = 450; // in pixels

  // We define the parentBounds of the tooltip used to calculate the tooltip position
  // and avoid the tooltip to be cut by the edge of the screen on the left side.
  // the parentBounds should be the same as the svgRef, with a minimum width of 2 * maxTooltipWidth.
  // this way, the tooltip may overflow on the right side, but never on the left side.

  const parentBounds = {
    width: Math.max(containerWidth, maxTooltipWidth * 2),
    height: containerHeight,
    right: 0,
    bottom: 0,
    left: 0,
    top: 0,
  };

  return {
    svgRef,
    tooltipStyles,
    tooltipPositionSet,
    isDraggingAnnotation,
    setIsDraggingAnnotation,
    parentBounds,
    showTooltip,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
  };
};
