import { Button, Header, Title } from '@allisone/react-components';
import { useTranslation } from 'react-i18next';

import { AmountToPaidTodayTooltip } from '../components';
import { EnrichedBilling } from 'src/common/types';
import { useFormatDate } from 'src/controller/hooks/useDate';
import { getCurrency } from 'src/controller/utils/basics/getCurrency';

type Columns = Header<EnrichedBilling>[];

export const useGetColumns = (onPay: (url: string) => void): Columns => {
  const { t } = useTranslation('centrePaymentModal');
  const formatDate = useFormatDate();

  const columns: Columns = [
    {
      key: 'centre',
      title: t('columns.name'),
      accessor: (data) => data.locationName,
      show: true,
      render: (value: string) => (
        <Title weight="bold" level="4">
          {value}
        </Title>
      ),
    },
    {
      key: 'formule',
      title: t('columns.formula'),
      accessor: (data) => data.price.productName,
      show: true,
    },
    {
      key: 'quantity',
      title: t('columns.quantity'),
      accessor: (data) => data.quantity,
      show: true,
    },
    {
      key: 'unitPrice',
      title: t('columns.unitPriceExclTax'),
      accessor: (data) => data.unitAmountExclTax / 100,
      show: true,
      render: (text: string, data: EnrichedBilling) => (
        <>
          {text} {getCurrency(data.price.currency)}
        </>
      ),
    },
    {
      key: 'totalPriceExclTax',
      title: t('columns.totalPriceExclTax'),
      accessor: (data) => (data.unitAmountExclTax / 100) * data.quantity,
      show: true,
      render: (text: string, data: EnrichedBilling) => (
        <>
          {text} {getCurrency(data.price.currency)}
        </>
      ),
    },
    {
      key: 'totalPriceInclTax',
      title: t('columns.totalPriceInclTax'),
      accessor: (data) => (data.unitAmountInclTax / 100) * data.quantity,
      show: true,
      render: (text: string, data: EnrichedBilling) => (
        <>
          {text} {getCurrency(data.price.currency)}
        </>
      ),
    },
    {
      key: 'realBillingDate',
      title: t('columns.billingStartDate'),
      accessor: (data) => data.expectedBillingDate,
      show: true,
      render: (text: Date) => <>{formatDate(new Date(text))}</>,
    },
    {
      key: 'prorata',
      title: t('columns.amountToPay'),
      accessor: (data) =>
        data.prorata && data.prorata.totalInclTax ? data.prorata.totalInclTax / 100 : 'N/A',
      show: true,
      render: (text: string, data: EnrichedBilling) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.5rem',
          }}
        >
          <span>
            {text} {getCurrency(data.price.currency)}
          </span>
          {!!data.prorata?.totalInclTax && <AmountToPaidTodayTooltip billing={data} />}
        </div>
      ),
    },
    {
      key: 'action',
      accessor: (data) => data,
      show: true,
      render: (value: EnrichedBilling) => (
        <Button variant="primary" onClick={() => onPay(value.id)}>
          {t('columns.subscribe')}
        </Button>
      ),
    },
  ];
  return columns;
};
