import { Title } from '@allisone/react-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppStore } from 'src/controller/store';
import { isDentist } from 'src/controller/utils/User';

import styles from '../ConnectedLayout.module.scss';

export const Welcome: FC = () => {
  const { t } = useTranslation('home');
  const { user, currentRoles } = useAppStore();
  const title = t(isDentist(currentRoles) ? 'welcome.dentist' : 'welcome.other', {
    lastname: user?.lastname,
    firstname: user?.firstname,
  });

  return (
    <div className={styles.welcome}>
      <Title level="2">{title}</Title>
    </div>
  );
};
