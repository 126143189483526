import { Loader } from '@allisone/react-components';

import { ElementsTypesContext } from './ElementTypesContext';
import { useElementTypesProvider } from 'src/communication/hasura/query';
import { CFC } from 'src/ui/components/FCWithChildren';

export const ElementsTypesProvider: CFC = ({ children }) => {
  const { loading, elementTypes } = useElementTypesProvider();

  if (loading || !elementTypes) return <Loader screenCenter />;
  return (
    <ElementsTypesContext.Provider value={elementTypes}>{children}</ElementsTypesContext.Provider>
  );
};
