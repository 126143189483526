import { UserFieldsFragment } from 'src/communication/hasura/generated/graphql';

type Pathologies = {
  options: {
    treatments: {
      value: string;
    }[];
  }[];
}[];

export const computeHasTreatmentPreferences = (user: UserFieldsFragment) => {
  const pathologies: Pathologies | undefined =
    user.treatmentPreferences?.treatmentPreferences?.preferences?.pathologies;
  if (!pathologies) return false;
  return (
    pathologies.some((pathology) =>
      pathology.options.some((option) =>
        option.treatments.some((treatment) => treatment.value !== '')
      )
    ) || false
  );
};
