import { useContext } from 'react';

import { XrayAnnotationToolContext } from '..';
import { XrayAnnotationToolContextValues } from '../../types';

export const useSATContext = (): XrayAnnotationToolContextValues => {
  const value = useContext(XrayAnnotationToolContext);
  if (!value) throw new Error('useSATContext can only be used in children of SATProvider');
  return value;
};
