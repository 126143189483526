import { ApolloClient, InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';

import { link } from './link';

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          patients: offsetLimitPagination(),
        },
      },
    },
  }),
  connectToDevTools: import.meta.env.NODE_ENV === 'development',
});
