// keep this line at the very top, otherwise modals won't work
import '@allisone/react-components/lib/index.css';

import { Loader, setRootElement } from '@allisone/react-components';
import { ApolloProvider } from '@apollo/client';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { IntercomProvider } from 'react-use-intercom';

import { ReactComponent as SvgDefinitions } from './assets/definitions.svg';
import { StripeModalGuard } from './components/modals/StripeModalGuard';
import i18n from './i18n';
import { Router } from './router';
import { ToasterManager } from './router/ToasterManager';
import * as serviceWorker from './serviceWorker';
import { updateHeightViewport } from './updateHeightViewport';
import { client as apolloClient } from 'src/communication/apollo';
import { sentryInitializer } from 'src/communication/Sentry/sentryInitializer';
import { ConfigProvider } from 'src/controller/context/config/useConfigContext';
import { ElectronProvider, WatcherProvider } from 'src/controller/electron/context';
import { KeycloakAuthProvider } from 'src/controller/Keycloak/KeycloakAuthProvider';

import './assets/fonts/style.css';
import './index.scss';

(window as any).global = window;

sentryInitializer();
setRootElement('modal-root');
updateHeightViewport();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Suspense fallback={<Loader screenCenter />}>
    <React.StrictMode>
      <SvgDefinitions />
      <KeycloakAuthProvider>
        <ApolloProvider client={apolloClient}>
          <I18nextProvider i18n={i18n}>
            <IntercomProvider appId={import.meta.env.VITE_INTERCOM_ID} shouldInitialize>
              <ToasterManager>
                <ConfigProvider>
                  <StripeModalGuard>
                    <ElectronProvider>
                      <WatcherProvider>
                        <Router />
                      </WatcherProvider>
                    </ElectronProvider>
                  </StripeModalGuard>
                </ConfigProvider>
              </ToasterManager>
            </IntercomProvider>
          </I18nextProvider>
        </ApolloProvider>
      </KeycloakAuthProvider>
    </React.StrictMode>
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
