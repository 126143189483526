import { useToaster } from '@allisone/react-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdateUser } from 'src/communication/hasura/query';
import { useAppStore } from 'src/controller/store';

type Return = {
  showCgu: boolean;
  loading: boolean;
  showDisclaimer: boolean;
  handleClickCgu(): void;
  handleCloseCgu(): void;
  handleClickDisclaimer(): void;
  handleCloseDisclaimer(): void;
};

export const useModalGuard = (): Return => {
  const { t } = useTranslation('cgu');
  const { user, locationVersions } = useAppStore();
  const { updateUser, loading } = useUpdateUser();
  const { show } = useToaster();
  const [showCgu, setShowCgu] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const isCertified = locationVersions.fda || locationVersions.ukca || locationVersions.ce;

  useEffect(() => {
    if (!user) return;
    setShowDisclaimer(!user.disclaimerApprovedAt && !isCertified);
    setShowCgu(!user.cguApprovedAt);
  }, [user]);

  const handleClickCgu = () => {
    if (!user) return;
    updateUser(user.id, { cgu_approved_at: new Date().toISOString() })
      .then(() => setShowCgu(false))
      .catch(() => show({ text: t('error'), type: 'error' }));
  };

  const handleCloseCgu = () => setShowCgu(false);

  const handleClickDisclaimer = () => {
    if (!user) return;
    updateUser(user.id, { disclaimer_approved_at: new Date().toISOString() })
      .then(() => setShowDisclaimer(false))
      .catch(() => show({ text: t('error'), type: 'error' }));
  };

  const handleCloseDisclaimer = () => setShowDisclaimer(false);

  return {
    showCgu,
    loading,
    showDisclaimer,
    handleClickCgu,
    handleCloseCgu,
    handleClickDisclaimer,
    handleCloseDisclaimer,
  };
};
