import { ApolloError, gql, useLazyQuery } from '@apollo/client';

import { EnrichedBilling } from 'src/common/types';

const GET_BILLINGS = gql`
  query GetBillings {
    billings @rest(path: "/stripe/billings", method: "GET") {
      billings
      billingsNotFiltered
      skippable
      isBeneficiary
    }
  }
`;

type GetBillings = {
  billings: {
    billings: EnrichedBilling[];
    billingsNotFiltered: EnrichedBilling[];
    skippable: boolean;
    isBeneficiary: boolean;
  };
};

type UseBillings = {
  getBillings: () => void;
  billings: EnrichedBilling[];
  billingsNotFiltered: EnrichedBilling[];
  loading: boolean;
  error?: ApolloError;
  skippable: boolean;
  isBeneficiary: boolean;
};

export const useBillings = (): UseBillings => {
  const [getBillings, { data, loading, error }] = useLazyQuery<GetBillings>(GET_BILLINGS);

  return {
    getBillings,
    billings: data?.billings?.billings ?? [],
    billingsNotFiltered: data?.billings.billingsNotFiltered ?? data?.billings?.billings ?? [],
    skippable: data?.billings?.skippable || false,
    isBeneficiary: data?.billings.isBeneficiary || false,
    loading,
    error,
  };
};
