import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { EnrichedBilling } from 'src/common/types';
import { useBillings } from 'src/communication/hasura/query';
import { useGenerateCheckoutLink } from 'src/communication/hasura/query/stripe/useGenerateCheckoutLink';
import { useAppStore } from 'src/controller/store';
import { useModalGuard } from 'src/ui/router/layouts/Layout/ModalGuard/hooks';

type UseStripeModal = {
  onPay(id: string): void;
  handleClose: () => void;
  paymasters: string[];
  shouldShowStripeGuard: boolean;
  skippable: boolean;
  isBeneficiary: boolean;
  billings: EnrichedBilling[];
  error?: boolean;
};

const inactiveBillingPredicate = ({ subscriptionStatus }: EnrichedBilling) =>
  subscriptionStatus === 'created';
const activeBillingPredicate = ({ subscriptionStatus }: EnrichedBilling) =>
  ['active', 'not_started'].includes(subscriptionStatus);

export const useStripeGuard = (): UseStripeModal => {
  const { user } = useAppStore();
  const auth = useAuth();
  const { showCgu, showDisclaimer } = useModalGuard();
  const { getBillings, billings, skippable, isBeneficiary } = useBillings();

  useEffect(() => {
    if (!auth?.isAuthenticated || !user?.id) return;
    getBillings();
  }, [user?.id, auth?.isAuthenticated]);
  const generateCheckoutLink = useGenerateCheckoutLink();
  const [error, setError] = useState(false);
  const [shouldShowStripeGuardLocal, setShouldShowStripeGuardLocal] = useState(true);

  const hasActiveBillings = billings.some(activeBillingPredicate);
  const onPay = async (billingId: string) => {
    const { data, errors } = await generateCheckoutLink({ billingId });
    if (errors) {
      return setError(true);
    }
    if (data?.GetCheckoutUrl.url) window.location.replace(data.GetCheckoutUrl.url);
  };

  const handleClose = () => setShouldShowStripeGuardLocal(false);
  const inactiveBilling = billings.filter(inactiveBillingPredicate);
  const paymasters: string[] = [];
  billings.forEach(({ paymasterName, potentialPaymasterNames }) => {
    paymasterName && paymasters.push(paymasterName);
    potentialPaymasterNames && paymasters.push(...potentialPaymasterNames);
  });

  const isBeneficiaryAndHasActiveBillings = isBeneficiary && hasActiveBillings;
  const shouldShowStripeGuard = isBeneficiaryAndHasActiveBillings
    ? false
    : shouldShowStripeGuardLocal && inactiveBilling.length > 0 && !showCgu && !showDisclaimer;

  return {
    shouldShowStripeGuard,
    paymasters,
    billings: inactiveBilling,
    error,
    skippable,
    isBeneficiary,
    onPay,
    handleClose,
  };
};
