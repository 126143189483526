import { ElementTypeEnum, TreatmentEnum } from 'src/common/enums';

export const TreatmentAndElementTypeEnum = {
  ...TreatmentEnum,
  ...ElementTypeEnum,
  ALL_ON: 'ALL_ON',
  DENTAL_HYGIENE: 'DENTAL_HYGIENE',
  DENTURE_FULL: 'DENTURE_FULL',
  DENTURE_PARTIAL: 'DENTURE_PARTIAL',
  DENTURE_ACKERMAN: 'DENTURE_ACKERMAN',
  DENTURE_LOCATOR: 'DENTURE_LOCATOR',
};
