import { useContext } from 'react';

import { XRayContext } from '../context';
import { XRayValues } from '../types';

export const useXRay = (): XRayValues => {
  const XRay = useContext(XRayContext);
  if (!XRay) throw new Error('useXRay can only be used in children of XRayProvider');
  return XRay;
};
