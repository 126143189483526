import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

const IGNORE_ERRORS = [/^Loading chunk.*failed.*/, 'AbortError'];

export const sentryInitializer = () => {
  Sentry.init({
    // regex start with loading chunk and end with failed
    ignoreErrors: IGNORE_ERRORS,
    environment: import.meta.env.VITE_ENVIRONMENT,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        networkDetailAllowUrls: [/allisone\.ai/gm],
        maskAllText: true,
        blockAllMedia: true,
      }),
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
};
