// eslint-disable-next-line no-shadow
export enum EditionMode {
  UPDATING = 0,
  POLYGON = 1,
  VISUAL = 2,
  TOOTH_NUMBERING = 3,
  PERIODONTIC_UPDATING = 4,
  DRAW_RECT_SHAPE = 5,
  RETRO_BONE_LEVEL = 6,
  IDENTIFY_RADIOLUCENCY = 8,
  DRAW_TOOTH = 9,
}
