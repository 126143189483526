import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const GET_CHECKOUT_URL = gql`
  mutation GetCheckoutUrl($billingId: String!, $input: any) {
    GetCheckoutUrl(billingId: $billingId)
      @rest(path: "/stripe/checkoutSession/{args.billingId}", method: "GET") {
      url
    }
  }
`;

type GenerateCheckoutLink = {
  GetCheckoutUrl: {
    url: string;
  };
};

type Params = {
  billingId: string;
};

export const useGenerateCheckoutLink = () => {
  const [createLink] = useMutation<GenerateCheckoutLink>(GET_CHECKOUT_URL);

  return useCallback(async ({ billingId }: Params) => {
    return await createLink({ variables: { billingId } });
  }, []);
};
