import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

import { PatientData } from './types';
import { Role } from 'src/common/enums';
import { useLocations, useUsers } from 'src/communication/hasura/query';
import { UseUser } from 'src/communication/hasura/query/user/useUsers';

const patientSchema = yup
  .object()
  .shape({
    lastname: yup.string().required('upload.patient.lastname.required'),
    firstname: yup.string().required('upload.patient.firstname.required'),
    date: yup.date().nullable(true).required('upload.patient.date.required'),
    locationId: yup.string().required('upload.patient.locationId.required'),
    userId: yup.string().required('upload.patient.dentist.required'),
  })
  .required();

type Patient = yup.InferType<typeof patientSchema>;

type Props = {
  initialData?: PatientData;
};

type Return = {
  form: UseFormReturn<any, any>;
  onSwapClick(): void;
  locationId?: string;
  users?: UseUser[];
};

export const usePatientForm = ({ initialData }: Props): Return => {
  const { locations } = useLocations();

  const defaultValues = {
    date: new Date(),
    userId: initialData?.userId,
    locationId:
      locations && locations.length === 1 ? locations[0]?.id.toString() : initialData?.locationId,
    firstname: initialData?.firstname,
    lastname: initialData?.lastname,
  };

  const form = useForm<Patient>({
    defaultValues,
    resolver: yupResolver(patientSchema),
    mode: 'all',
  });

  const { setValue, watch, getValues } = form;
  const locationId = watch('locationId');
  const { users } = useUsers({
    roles: [Role.DENTIST],
    locationIds: locationId && [locationId],
    isEnabled: true,
    isValidated: true,
  });
  watch('firstname');
  watch('lastname');

  useEffect(() => {
    // auto-update location if only one location
    if (locations?.length === 1 && locations[0]?.id)
      setValue('locationId', locations[0].id.toString());
  }, [locations]);

  useEffect(() => {
    // auto-update user if only one user in the location
    if (users?.length === 1) setValue('userId', users[0]?.id, { shouldValidate: true });
  }, [users]);

  const onSwapClick = () => {
    const tmpFirstName = getValues('firstname');
    setValue('firstname', getValues('lastname'));
    setValue('lastname', tmpFirstName);
  };

  return { form, locationId, users, onSwapClick };
};
