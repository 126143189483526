import { Role } from 'src/common/enums';

export const areTreatmentPreferencesAvailable = (
  currentRoles: Role[],
  locationType?: string | null,
  isTreatmentPreferencesEnabled?: boolean | null
): boolean =>
  currentRoles.includes(Role.HEAD_OF) ||
  (currentRoles.includes(Role.DENTIST) && isTreatmentPreferencesEnabled) ||
  (currentRoles.includes(Role.DIRECTOR) && isTreatmentPreferencesEnabled) ||
  (currentRoles.includes(Role.DENTIST) && locationType === 'office');
