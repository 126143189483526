import { useToaster } from '@allisone/react-components';
import { ApolloError } from '@apollo/client';
import { useEffect } from 'react';

export const useErrorManager = (error?: ApolloError) => {
  const { show } = useToaster();

  useEffect(() => {
    if (!error) return;

    const { graphQLErrors, clientErrors, networkError } = error;

    // TODO cu-860pxvmy2: improve error message handling by providing translated and narrowed error message
    // https://app.clickup.com/t/860pxvmy2
    // This component is obsolete and errors should be handled case by case.
    for (const error of [graphQLErrors, clientErrors, networkError]) {
      if (error instanceof Error) {
        show({ type: 'error', text: error.message });
      }
      if (Array.isArray(error)) {
        error.forEach((e) => show({ type: 'error', text: e.message }));
      }
    }
  }, [error]);
};
