export const updateHeightViewport = () => {
  const handleResize = () => {
    const updateViewportHeight = () => {
      document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
    };
    requestAnimationFrame(updateViewportHeight);
  };
  handleResize();
  return handleResize;
};

window.addEventListener('resize', updateHeightViewport());
