import axios, { AxiosResponse } from 'axios';

import { AuthTokens } from 'src/common/types';

type Params = {
  url: string;
  token: AuthTokens;
  role: string;
  data?: Object;
};

export const axiosPost = async ({
  token,
  role,
  data = {},
  url,
}: Params): Promise<AxiosResponse<any>> => {
  const authorization = `${token.token_type} ${token.access_token}`;
  const headers = {
    'x-hasura-role': role,
    authorization,
  };

  return axios.post(url, data, { headers });
};

export const axiosGet = async ({
  token,
  role,
  url,
  params,
}: {
  token: AuthTokens;
  role: string;
  url: string;
  params?: Object;
}): Promise<AxiosResponse<any>> => {
  const authorization = `${token.token_type} ${token.access_token}`;
  const headers = {
    'x-hasura-role': role,
    authorization,
  };
  return axios.get(url, { headers, params });
};

export const axiosPut = async ({ token, role, data, url }: Params): Promise<AxiosResponse<any>> => {
  const authorization = `${token.token_type} ${token.access_token}`;
  const headers = {
    'x-hasura-role': role,
    authorization,
  };

  return axios.put(url, data, { headers });
};
