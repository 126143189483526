import { useNavigate } from 'react-router';

import { BrandLogo } from '../../../components';
import { CFC } from 'src/ui/components/FCWithChildren';

import styles from './FullScreenLayout.module.scss';

type Props = { title?: string };

export const FullScreenLayout: CFC<Props> = ({ children, title }) => {
  const navigateTo = useNavigate();
  const onLogoClick = () => navigateTo('/home');

  return (
    <div className={styles.fullScreenLayout}>
      <button type="button" className={styles.logo} onClick={onLogoClick}>
        <BrandLogo className={styles.logo} />
      </button>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
