const CACHE_DURATION = 1000 * 60 * 60 * 4; //4h

type cacheType = {
  date: Date;
  urlPromise: Promise<string>;
};

const cache = new Map<string, cacheType>();

const get = (key: string): Promise<string> | undefined => {
  const cacheValue = cache.get(key);
  if (!cacheValue) return;
  const cacheTime = new Date().getTime() - cacheValue.date.getTime();
  if (cacheTime > CACHE_DURATION) return;
  return cacheValue.urlPromise;
};

const set = (key: string, urlPromise: Promise<string>) => {
  const cacheValue = {
    date: new Date(),
    urlPromise,
  };
  cache.set(key, cacheValue);
};

const reset = (key: string) => {
  cache.delete(key);
};

export const urlCache = {
  get,
  set,
  reset,
};
