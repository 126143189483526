export enum ElementTypeEnum {
  AMALGAM = 'AMALGAM',
  DECAY = 'DECAY',
  CROWN = 'CROWN',
  ROOT_CANAL = 'ROOT_CANAL',
  IMPLANT = 'IMPLANT',
  INLAY_ONLAY = 'INLAY_ONLAY',
  PERIAPICAL_LESION = 'PERIAPICAL_LESION',
  ROOT = 'ROOT',
  FILLING = 'FILLING',
  TEMPORARY_CROWN = 'TEMPORARY_CROWN',
  CALCULUS = 'CALCULUS',
  INSTRUMENT_FRACTURE = 'INSTRUMENT_FRACTURE',
  POST = 'POST',
  SCREW_POST = 'SCREW_POST',
  POST_CORE = 'POST_CORE',
  VENEER = 'VENEER',
  BRIDGE = 'BRIDGE',
  MISSING_TOOTH = 'MISSING_TOOTH',
  PERIODONTICS = 'PERIODONTICS',
  RADIOLUCENCY = 'RADIOLUCENCY',
  ARCADE_EDENTATION = 'ARCADE_EDENTATION',
}
