import { OptionProps, Select } from '@allisone/react-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Locale } from 'src/common/types';
import { useAppStore } from 'src/controller/store';
import { getLocaleFromString } from 'src/controller/utils/app/getLocaleFromString';

const AVAILABLE_LOCALES: { value: Locale; label: string }[] = Object.values(Locale).map(
  (locale) => ({
    label: locale.toUpperCase(),
    value: locale,
  })
);

export const SelectLanguage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlLocalParam = searchParams.get('locale');
  const { i18n } = useTranslation();
  const { setLocale } = useAppStore();
  const value = urlLocalParam
    ? getLocaleFromString(urlLocalParam)
    : getLocaleFromString(i18n.language);
  const onSelect = (item: OptionProps<Locale>) => {
    i18n.changeLanguage(item.value);
    setLocale(item.value);
    if (urlLocalParam) {
      searchParams.delete('locale');
      setSearchParams(searchParams);
    }
  };

  return <Select value={value} options={AVAILABLE_LOCALES} onSelect={onSelect} />;
};
