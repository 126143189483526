export const getMimeType = (fileName: string) => {
  const extension = fileName.split('.').pop()?.toLowerCase();
  let mimetype = 'application/octet-stream';
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      mimetype = 'image/jpeg';
      break;
    case 'tiff':
    case 'tif':
      mimetype = 'image/tiff';
      break;
    case 'dicom':
    case 'dcm':
      mimetype = 'application/dicom';
      break;
    case 'ico':
      mimetype = 'image/x-icon';
      break;
    case 'pdf':
      mimetype = 'application/pdf';
      break;
    case 'bmp':
    case 'png':
    case 'webp':
    case 'gif':
      mimetype = `image/${extension}`; // PNGs handled here
      break;
  }
  return mimetype;
};
