import { FC } from 'react';
import { Navigate, Outlet } from 'react-router';

import { Role } from 'src/common/enums';
import { useAppStore } from 'src/controller/store';
import { isStrictlyAccountant } from 'src/controller/utils/User';

type Props = {
  allowedRoles?: Role[];
};

export const ProtectedRoute: FC<Props> = ({ allowedRoles }) => {
  const { currentRoles } = useAppStore();

  const userIsNotAllowed =
    currentRoles && allowedRoles && !allowedRoles.some((role) => currentRoles.includes(role));

  return userIsNotAllowed ? (
    <Navigate to={{ pathname: isStrictlyAccountant(currentRoles) ? '/locations' : '/' }} replace />
  ) : (
    <Outlet />
  );
};
