import { ScannerImgData } from 'src/common/types';

type ImgList = ScannerImgData[] | undefined;
type Action =
  | {
      type: 'watcher-list';
      payload: ScannerImgData[];
    }
  | {
      type: 'watcher-newfile';
      payload: ScannerImgData;
    };

export const reducer = (imgDataList: ImgList, action: Action): ImgList => {
  if (action.type === 'watcher-newfile') {
    const arr = Object.assign([], imgDataList);
    if (arr) {
      if (arr.length >= 6) {
        arr.splice(5);
      }
      arr.unshift(action.payload);
    }
    return arr;
  } else if (action.type === 'watcher-list') {
    return action.payload;
  } else {
    return undefined;
  }
};
