import { ChartBarIcon, FolderIcon, HomeIcon, Link } from '@allisone/react-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useAppStore } from 'src/controller/store';
import { isAccountant } from 'src/controller/utils/User';

export const useLinks = (): Link[] => {
  const { t } = useTranslation('layout');
  const { pathname } = useLocation();
  const { currentRoles } = useAppStore();
  const isAccountantRole = isAccountant(currentRoles);

  return useMemo(() => {
    const isActive = (...paths: string[]) => paths.some((path) => pathname.startsWith(path));

    const style = { width: '1.5rem', minWidth: '1.5rem' };

    return [
      {
        path: '/',
        text: t('menu.home'),
        logo: <HomeIcon style={style} />,
        active: pathname === '/',
        dataTestId: 'MenuHome',
        show: !isAccountantRole,
      },
      {
        path: '/patients',
        text: t('menu.patients'),
        logo: <FolderIcon style={style} />,
        active: isActive('/patients', '/treatment'),
        dataTestId: 'MenuPatients',
        show: !isAccountantRole,
      },
      {
        path: '/statistics',
        text: t('menu.stats'),
        logo: <ChartBarIcon style={style} />,
        active: isActive('/statistics'),
        dataTestId: 'MenuStatistics',
        show: !isAccountantRole,
      },
    ].filter((m) => m.show);
  }, [pathname, t, isAccountantRole]);
};
