import { createContext, useContext } from 'react';

import { ConstructionAction, ProposedGroup, ProposedGroupWithState } from './types';
import { WaitingTreatment } from 'src/ui/components/Treatment/planSide/types';

export type ConstructionContextValue = {
  proposedTreatments: ProposedGroupWithState[];
  selectedTreatments: WaitingTreatment[][];
  conflictTreatments: WaitingTreatment[][];
  pendingTreatments: WaitingTreatment[];
  enabledTreatmentsCount: number;
  createTreatments(treatments: WaitingTreatment[]): void;
  removeTreatments(treatments: WaitingTreatment[]): void;
  replaceTreatments(newTreatments: WaitingTreatment[], oldTreatments?: WaitingTreatment[]): void;
  dispatch(value: ConstructionAction): void;
  setProposedTreatments(proposedTreatments: ProposedGroup[]): void;
};

export const ConstructionContext = createContext<ConstructionContextValue | null>(null);

export const useConstructionContext = () => {
  const context = useContext(ConstructionContext);

  if (!context) {
    throw new Error('ConstructionContext cannot be used outside of ConstructionProvider');
  }

  return context;
};
