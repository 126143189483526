import { getCdnUrlFromImageKey } from 'src/ui/components/Image/fetchImage';

export const getResellerLogoUrl = (
  reseller: string | undefined,
  withBrandName?: boolean
): string | null => {
  const isOrislineReseller = reseller?.toLocaleLowerCase().includes('orisline');
  const isKopfwerkReseller = reseller?.toLocaleLowerCase().includes('kopfwerk');
  const orislineLogoName = withBrandName ? 'OrislineFull' : 'Orisline';

  let logoName = '';
  if (isOrislineReseller) {
    logoName = orislineLogoName;
  } else if (isKopfwerkReseller) {
    logoName = 'Kopfwerk';
  } else return null;

  return getCdnUrlFromImageKey(`reseller/${logoName}Logo.png`);
};
