import {
  RetroBoneLevelPoint,
  RetroBoneLevelSegment,
  RetroSnakeCasePeriodontics,
} from '@allisone/react-components';

// This function is used for mapping snake_case from ML API to camelCase for Storybook
export const buildRetroBoneLevel = (
  periodontics: RetroSnakeCasePeriodontics[] | undefined
): RetroBoneLevelSegment[] =>
  periodontics?.map(({ segment, on_tooth }) => {
    const camelCaseSegment: RetroBoneLevelPoint[] = segment.map(
      ({ detection_coords, detection_score, element_type, element_type_full }) => ({
        detectionCoords: detection_coords,
        detectionScore: detection_score,
        elementType: element_type_full ?? element_type,
        elementTypeFull: '',
      })
    );

    return { segment: camelCaseSegment, onTooth: on_tooth };
  }) ?? [];
