import { useMemo } from 'react';

import { useAppStore } from '..';
import { computeHasTreatmentPreferences } from './helpers/computeHasTreatmentPreferences';
import { UserGetters } from './types';

export const useUserGetters = (): UserGetters => {
  const { user } = useAppStore();

  const hasTreatmentPreferences = useMemo(() => {
    if (!user) return false;
    return computeHasTreatmentPreferences(user);
  }, [user]);

  return {
    hasTreatmentPreferences,
  };
};
