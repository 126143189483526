import { ClinicExam } from '../types/ClinicExam';
import { ClinicalExamsEnum } from './ClinicalExamsEnum';

export const initialClinicalExams: ClinicExam[] = [
  {
    examName: ClinicalExamsEnum.DECAYED_TEETH,
    teeth: [],
  },
  {
    examName: ClinicalExamsEnum.DAMAGED_TEETH,
    teeth: [],
  },
  {
    examName: ClinicalExamsEnum.LOOSE_TEETH,
    teeth: [],
  },
  {
    examName: ClinicalExamsEnum.INFLAMMATION,
    teeth: [],
  },
  {
    examName: ClinicalExamsEnum.MISSING_TEETH,
    teeth: [],
  },
  {
    examName: ClinicalExamsEnum.OCCLUSION,
    teeth: [],
  },
];
